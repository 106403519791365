import React, { Children } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { ContentCheck } from '@fs/zion-icon'
import { useHistory, useQueryParams } from '@fs/zion-router'
import { DialogOverlay, DialogOverlayActions, Row, Paragraph, H3, Separator } from '@fs/zion-ui'
import { useFeatureFlag } from '@fs/zion-flags'
import { PillButton } from '../buttons'
import RainbowText from '../RainbowText'
import { useLoggedOutOverlay } from '../../providers'
import { useIsMdWidth } from '../../hooks'
import { authConfig, flavors } from '../../config'

const reasonItemCss = css`
  list-style: none;
`

const listCss = css`
  all: unset;
`

function ReasonItem({ children }) {
  return (
    <li css={reasonItemCss}>
      <Row gutters="xs">
        <ContentCheck color={flavors.apple.solid} />
        <Paragraph size="sm" as="span">
          {children}
        </Paragraph>
      </Row>
    </li>
  )
}

export function ReasonsToLogIn() {
  const chatFlag = useFeatureFlag('rootstech_chat')
  const rarFlag = useFeatureFlag('rootstech_rar')
  const [t] = useTranslation()
  const { listItems } = useLoggedOutOverlay()

  return (
    <>
      <H3 size="H5">{t('logged-out.subheader', 'Watch videos online plus:')}</H3>
      <Separator size="sm" />
      <ul css={listCss}>
        {Children.toArray(listItems.map((item) => <ReasonItem>{item}</ReasonItem>))}
        {chatFlag.isOn && (
          <ReasonItem>{t('logged-out.reason2', 'Chat with industry experts and other attendees')}</ReasonItem>
        )}
        {rarFlag.isOn && <ReasonItem>{t('logged-out.reason3', 'See your relatives attending RootsTech')}</ReasonItem>}
        <ReasonItem>{t('logged-out.reason5', 'Access to all RootsTech content')}</ReasonItem>
      </ul>
    </>
  )
}

export default function LoggedOutOverlay({ guestRedirect = '/', ...overlay }) {
  const [t] = useTranslation()
  const history = useHistory()
  const pageQuery = useQueryParams()
  const { query } = useLoggedOutOverlay()
  const isMdWidth = useIsMdWidth()

  const onDynamicRedirect = (to) => {
    if (to === '/back') {
      history.goBack()
    } else if (to) {
      history.go(to)
    }
    overlay.close()
  }

  let redirect
  if (query) {
    const redirectUrl = new URL(window.location.toString().split('?')[0])
    if (Object.keys(query).length > 0) {
      const redirectQuery = { ...pageQuery.query, ...(query ?? {}) }
      Object.entries(redirectQuery).forEach(([k, v]) => redirectUrl.searchParams.set(k, v))
    }
    redirect = redirectUrl.toString()
  } else {
    redirect = window.location.toString()
  }

  return (
    <DialogOverlay
      {...overlay}
      title={<RainbowText>{t('logged-out.header', 'Sign In to Your Account')}</RainbowText>}
      footer={
        <DialogOverlayActions wrap>
          <PillButton
            data-testid="continue-as-guest"
            emphasis="low"
            onClick={() => onDynamicRedirect(guestRedirect)}
            color="blue"
            linkName="No Thanks"
            size={isMdWidth ? 'sm' : undefined}
          >
            {t('logged-out.no-thanks', 'No Thanks')}
          </PillButton>
          <PillButton
            to={`${authConfig.registerPath}?returnUrl=${encodeURIComponent(redirect)}`}
            external
            target="_self"
            emphasis="medium"
            data-testid="register"
            linkName="Create an Account"
            size={isMdWidth ? 'sm' : undefined}
          >
            {t('logged-out.create-an-account', 'Create an Account')}
          </PillButton>
          <PillButton
            data-testid="sign-in-LoggedOutOverlay"
            external
            target="_self"
            to={`${authConfig.loginPath}?returnUrl=${encodeURIComponent(redirect)}`}
            emphasis="high"
            linkName="Sign In"
            size={isMdWidth ? 'sm' : undefined}
          >
            {t('common-ui:sign-in.action', 'Sign In')}
          </PillButton>
        </DialogOverlayActions>
      }
    >
      <ReasonsToLogIn />
    </DialogOverlay>
  )
}
