import React from 'react'
import { Separator, Row, H2 } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { PillButton } from '../buttons'
import RainbowText from '../RainbowText'
import ResponsiveSectionBand from '../ResponsiveSectionBand'

export default function SubscribeUpdatesSection({ flavor = 'seafoam' }) {
  const [t] = useTranslation()

  return (
    <ResponsiveSectionBand maxWidth="sm">
      <H2 centered supplementary>
        <RainbowText flavor={flavor}>
          {t('home.reg.subscribe.heading', 'Want real-time updates leading up to RootsTech 2025?')}
        </RainbowText>
      </H2>
      <Separator size="sm" />
      <Row alignX="center">
        <PillButton to="/subscription" linkName="Subscribe for Updates" emphasis="medium">
          {t('conference-announce.subscribe.for.updates', 'Subscribe for Updates')}
        </PillButton>
      </Row>
    </ResponsiveSectionBand>
  )
}
