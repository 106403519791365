import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckboxList, DialogOverlay, DialogOverlayActions, Ellipsis, ListItem, useStatusOverlay } from '@fs/zion-ui'
import { playlistsConfig } from '../../../config'
import { usePlaylistsContextV2 } from '../../../providers/PlaylistsProviderV2'
import CreatePlaylistOverlay from './CreatePlaylistOverlay'
import CreatePlaylistButtonV2 from './CreatePlaylistButtonV2'
import PillButton from '../../../lib/buttons/PillButton'
import notifySentry from '../../../lib/helpers/sentryNotifier'

const usePlaylistAddItemErrorHandling = () => {
  const [t] = useTranslation()
  const showStatusOverlay = useStatusOverlay()
  const { playlists } = usePlaylistsContextV2()

  return (playlistId, error) => {
    let message = t(
      'playlists.errors.generic.add-item',
      'We’re sorry, but there was an issue adding the session to your playlist. Please try again shortly or refresh your browser.'
    )
    if (error.response.status === 409) {
      message = t(
        'playlists.errors.playlist-full',
        'The {name} playlist has reached the maximum limit of {max} videos. Please delete some videos or create a new playlist.',
        {
          name: playlists.get(playlistId).name,
          max: playlistsConfig.sessionLimit,
        }
      )
    } else {
      notifySentry({ ...error.response, error })
    }

    showStatusOverlay({
      message,
      type: 'error',
      dismissable: true,
    })
  }
}

const usePlaylistRemoveItemErrorHandling = () => {
  const [t] = useTranslation()
  const showStatusOverlay = useStatusOverlay()

  return (err) => {
    showStatusOverlay({
      message: t(
        'playlists.errors.generic.remove-item',
        'We’re sorry, but there was an issue removing the session to your playlist. Please try again shortly or refresh your browser.'
      ),
      type: 'error',
      dismissable: true,
    })

    notifySentry({ ...err.response, err })
  }
}

export default function PlaylistManagementOverlay({ sessionId, target }) {
  const [t] = useTranslation()
  const { playlists, findPlaylistsWithItem, removeFromPlaylist, addToPlaylist } = usePlaylistsContextV2()
  const [containerPlaylists, setContainerPlaylists] = useState()
  const [isCreatingNewPlaylist, setCreateNewPlaylist] = useState(false)
  const showStatusOverlay = useStatusOverlay()

  useEffect(() => {
    if (!containerPlaylists) {
      findPlaylistsWithItem({ itemId: sessionId }).then((results) => {
        const ids = results.map((p) => p.id)
        setContainerPlaylists(new Set(ids))
      })
    }
  }, [containerPlaylists, findPlaylistsWithItem, sessionId])

  const handleAddItemError = usePlaylistAddItemErrorHandling()
  const handleRemoveItemError = usePlaylistRemoveItemErrorHandling()

  const selectionChangedCallback = useCallback(
    (selectedIds) => {
      setContainerPlaylists((old = new Set()) => {
        const latest = new Set(selectedIds)
        const oldIds = [...old.values()]
        oldIds
          .filter((id) => !latest.has(id))
          .forEach((playlistId) => {
            removeFromPlaylist({ playlistId, itemId: sessionId }).catch((err) => handleRemoveItemError(playlistId, err))
          })
        selectedIds
          .filter((id) => !old.has(id))
          .forEach((playlistId) => {
            const playlist = playlists.get(playlistId)
            const needsWarningMessage = playlist.size + 1 >= playlistsConfig.sessionLimit
            addToPlaylist({ playlistId, items: [sessionId] })
              .then(() => {
                if (needsWarningMessage) {
                  showStatusOverlay({
                    message: t(
                      'playlists.alerts.size-warning',
                      'The {name} playlist is getting close to the maximum limit of {max} videos.',
                      { name: playlist.name, max: playlistsConfig.sessionLimit }
                    ),
                    type: 'warning',
                    dismissable: true,
                  })
                }
              })
              .catch((err) => handleAddItemError(playlistId, err))
          })
        return latest
      })
    },
    [
      addToPlaylist,
      handleAddItemError,
      handleRemoveItemError,
      playlists,
      removeFromPlaylist,
      sessionId,
      showStatusOverlay,
      t,
    ]
  )

  if (isCreatingNewPlaylist) {
    return (
      <CreatePlaylistOverlay
        {...target}
        sessionId={sessionId}
        onPlaylistCreated={(newPlaylist) => {
          setContainerPlaylists((old) => new Set([...old, newPlaylist.id]))
          setCreateNewPlaylist(false)
        }}
      />
    )
  }

  const canCreatePlaylists = [...playlists.values()].filter((p) => !p.parent).length < playlistsConfig.playlistLimit

  return (
    <DialogOverlay
      {...target}
      size="sm"
      title={t('playlists.overlay.heading', 'Add to...')}
      footer={
        <DialogOverlayActions>
          {canCreatePlaylists && <CreatePlaylistButtonV2 onClick={() => setCreateNewPlaylist(true)} />}
          <PillButton emphasis="high" onClick={() => target.close()}>
            {t('common-ui:close.action', 'Close')}
          </PillButton>
        </DialogOverlayActions>
      }
    >
      {containerPlaylists && (
        <CheckboxList onSelectionChange={selectionChangedCallback} initialSelection={[...containerPlaylists.values()]}>
          {[...playlists.values()]
            .filter((p) => !p.parent)
            .map((playlist) => {
              const full = !playlist.default && playlist.size >= playlistsConfig.sessionLimit

              let secondaryText = t('playlists.playlist.size', '{ playlistSize } videos', {
                playlistSize: playlist.size || 0,
              })
              if (full) {
                secondaryText = t('playlists.playlists.size-status', '{ playlistSize } videos (FULL)', {
                  playlistSize: playlist.size,
                })
              }

              return (
                <ListItem
                  data-testid={`${playlist.default ? 'watch-later' : playlist.name}-playlist`}
                  dense
                  disabled={full}
                  primaryText={
                    <Ellipsis>
                      <strong>{playlist.name}</strong>
                    </Ellipsis>
                  }
                  secondaryText={secondaryText}
                  key={playlist.id}
                  name={playlist.id}
                  linkName={`Add to ${playlist.default ? 'Default' : 'Other'} Playlist`}
                />
              )
            })}
        </CheckboxList>
      )}
    </DialogOverlay>
  )
}
