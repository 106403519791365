import React, { createContext, useContext, useState } from 'react'
import { getNowTimestamp } from '../../../lib/helpers'

const TimeContext = createContext()
export const useTimeTravel = () => useContext(TimeContext)

export const TimeTravelProvider = ({ children }) => {
  const [temporalNow, setTemporalNow] = useState(getNowTimestamp())

  // FIXME: does not work as expected when in a timeout/interval
  const getNow = () => {
    // positive num means the now state is in the future
    // negative num means the now state is in the past
    const difference = temporalNow - getNowTimestamp()
    return getNowTimestamp() + difference
  }

  return (
    <TimeContext.Provider
      value={{
        getNow,
        setNow: setTemporalNow,
      }}
    >
      {children}
    </TimeContext.Provider>
  )
}
