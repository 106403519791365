import React from 'react'
import PropTypes from 'prop-types'
import { Separator, Row, H1, Overline, Subheading, Paragraph, useContainerWidth } from '@fs/zion-ui'
import { SectionDescription } from '../section-card'
import RainbowText from '../RainbowText'

export default function PageHeading({
  heading,
  subHeading,
  description,
  overline,
  fullWidth,
  bold,
  centered,
  dataTestId,
  children,
  action,
  flavor,
  size = 'H1',
  supplementary,
  isCleanDescription,
  'data-testid': testId,
}) {
  const atWidth = useContainerWidth()
  const formattedHeading = bold ? <strong>{heading}</strong> : heading
  return heading ? (
    <>
      <H1 data-testid={testId} size={size} centered={centered} supplementary={supplementary}>
        {overline && (
          <Overline>
            <Paragraph size={atWidth({ default: 'sm', lg: 'md' })} centered={centered}>
              {overline}
            </Paragraph>
          </Overline>
        )}
        <RainbowText flavor={flavor}>{formattedHeading}</RainbowText>
        {subHeading && (
          <>
            <Separator size="xxs" />
            <Subheading>{subHeading}</Subheading>
          </>
        )}
      </H1>
      {description && (
        <>
          <Separator size="xs" />
          <SectionDescription
            isClean={isCleanDescription}
            fullWidth={fullWidth}
            bold={bold}
            centered={centered}
            dataTestId={dataTestId}
          >
            {description}
          </SectionDescription>
        </>
      )}
      {action && <Separator size="lg" />}
      {action && <Row alignX={centered ? 'center' : 'start'}>{action}</Row>}
      {action && <Separator size="lg" />}
      {children}
    </>
  ) : null
}

PageHeading.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}
