import { getPlaylistsForItem } from '../../api/rootstech/playlists'
import { deepCopy } from './functionalHelpers'

export const markItemsInPlaylists = async ({ items, signedIn }) => {
  if (!items) return []

  let clonedItems
  if (Array.isArray(items)) {
    clonedItems = deepCopy(items)
  } else if (items.items) {
    clonedItems = deepCopy(items.items)
  } else {
    return []
  }

  if (!signedIn) return clonedItems

  const itemsWithContentId = clonedItems.filter((item) => item.contentId !== null)

  if (itemsWithContentId.length === 0) return clonedItems

  const requests = itemsWithContentId.map((item) => getPlaylistsForItem({ sessionId: item.id, signedIn }))
  const results = await Promise.all(requests).then((responses) =>
    responses.map((response) => response.data.results.length > 0)
  )

  for (let i = 0; i < itemsWithContentId.length; i++) {
    itemsWithContentId[i].isInPlaylist = results[i]
  }

  return clonedItems
}

export const markGroupsOfItemsInPlaylists = async ({ items, signedIn }) => {
  if (!items) return []

  const clonedItems = deepCopy(items)
  return Promise.all(clonedItems.map((series) => markItemsInPlaylists({ items: series, signedIn })))
}
