import React from 'react'
import ResponsiveSeparator from './ResponsiveSeparator'
import ResponsiveLayoutBand from './ResponsiveLayoutBand'

export default function ResponsiveSectionBand({ children, ...props }) {
  return (
    <>
      <ResponsiveSeparator />
      <ResponsiveLayoutBand as="section" {...props}>
        {children}
      </ResponsiveLayoutBand>
    </>
  )
}
