import React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { useLoggedOutOverlay } from '../../providers'

const ChatLoginButton = ({ ariaLabel, children, asComponent, chatRoute, query, ...props }) => {
  const { overlay } = useLoggedOutOverlay(chatRoute ? { showChat: chatRoute, tracking: 'Chat' } : query)
  const [t] = useTranslation()

  const Tag = asComponent || IconButton

  return (
    <Tag
      aria-label="Chat"
      {...props}
      onClick={overlay.handleClick}
      title={ariaLabel}
      data-selector="chatLoginOverlayButton"
      linkName="Chat"
    >
      {children || t('help.overlay.chat.primary', 'Chat')}
    </Tag>
  )
}

export default ChatLoginButton

ChatLoginButton.propTypes = {
  ariaLabel: PropTypes.string,
  asComponent: PropTypes.func,
  chatRoute: PropTypes.string,
  query: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}
