import React, { Children } from 'react'
import { useFeatureFlag } from '@fs/zion-flags'
import { useTranslation } from 'react-i18next'
import { Paragraph, Row } from '@fs/zion-ui'
import { StrongLink, getRelativePath } from '..'

export default function CreatorExhibitorRow({ exhibitors = [], creators = [] }) {
  const expoHallFlag = useFeatureFlag('rootstech_expohall')
  const [t] = useTranslation()

  if (creators.length === 0 && exhibitors.length === 0) {
    return null
  }

  return (
    <Paragraph as="div" size="sm">
      <Row alignY="middle" gutters="nano" wrap>
        {Children.toArray(
          creators?.map((creator) => {
            return (
              <StrongLink
                rel="author"
                to={getRelativePath(creator?.url)}
                linkName={`Creator: ${creator?.name}`}
                data-testid="list-item-creator"
              >
                {creator?.name}
              </StrongLink>
            )
          })
        )}
        {creators.length ? <span style={{ width: 24 }} /> : null}
        {exhibitors.length && expoHallFlag.isOn ? (
          <Row gutters="nano" wrap>
            {t('session.sponsored-by.label', 'Sponsored by:')}
            {Children.toArray(
              exhibitors.map((exhibitor) => {
                return (
                  <StrongLink
                    rel="exhibitor"
                    to={getRelativePath(exhibitor?.url)}
                    linkName={`Exhibitor: ${exhibitor?.name}`}
                    data-testid="list-item-sponsored"
                  >
                    {exhibitor?.name}
                  </StrongLink>
                )
              })
            )}
          </Row>
        ) : null}
      </Row>
    </Paragraph>
  )
}
