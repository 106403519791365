import { colors } from '@fs/zion-ui'

const flavors = {
  default: 'blueberry',
  mango: {
    gradient: 'linear-gradient(135deg, #F9A95E 8%, #C31A86 92%)',
    solid: '#F9A95E',
    filmedGradient: 'linear-gradient(90deg, #F9A95E 0%, #C31A8600 100%)',
  },
  blueberry: {
    gradient: 'linear-gradient(135deg, #C31A86 8%, #933FA0 50%, #00ADEE 92%)',
    solid: '#C31A86',
    filmedGradient: 'linear-gradient(90deg, #C31A86 0%, #933FA0 50%, #00ADEE00 100%)',
  },
  apple: {
    gradient: 'linear-gradient(135deg, #00ADEE 8%, #64BC55 92%)',
    solid: '#64BC55',
    filmedGradient: 'linear-gradient(90deg, #00ADEE 0%, #64BC5500 100%)',
  },
  dusk: {
    gradient: 'linear-gradient(135deg, #4FCCB8 8%, #883AC3 92%)',
    solid: '#883AC3',
    filmedGradient: 'linear-gradient(90deg, #4FCCB8 0%, #883AC300 100%)',
  },
  sunset: {
    gradient: 'linear-gradient(135deg, #4DB3D5 8%, #D37AA8 50%, #FF6699 92%)',
    solid: '#FF6699',
    filmedGradient: 'linear-gradient(90deg, #4FCCB8 0%, #D37AA8 50% #FF669900 100%)',
  },
  fire: {
    gradient: 'linear-gradient(135deg, #FFCC66 8%, #EF614C 92%)',
    solid: '#EF614C',
    filmedGradient: 'linear-gradient(90deg, #FFCC66 0%, #EF614C00 100%)',
  },
  sunrise: {
    gradient: 'linear-gradient(135deg, #99DEDF 8%, #FFCC66 92%)',
    solid: '#FFCC66',
    filmedGradient: 'linear-gradient(90deg, #FFCC66 0%, #EF614C00 100%)',
  },
  lava: {
    gradient: 'linear-gradient(135deg, #FF82B2 8%, #EE2541 92%)',
    solid: '#EE2541',
    filmedGradient: 'linear-gradient(90deg, #FF82B2 0%, #EE254100 100%)',
  },
  seafoam: {
    gradient: 'linear-gradient(90deg, #00EBC8 8%, #029DF4 92%)',
    solid: '#029DF4',
    filmedGradient: 'linear-gradient(90deg, #00EBC8 0%, #029DF400 100%)',
  },
  charcoal: {
    gradient: 'linear-gradient(0deg, #252525cc 30%, transparent 100%)',
    solid: '#252525',
    filmedGradient: 'linear-gradient(90deg, #252525 30%, #25252535 100%)',
    textColor: 'white',
  },
  smoke: {
    gradient: 'linear-gradient(0deg, #252525cc 0%, transparent 55%)',
    solid: '#252525',
    filmedGradient: 'linear-gradient(90deg, #252525 30%, #25252535 100%)',
  },
  flavorless: {
    gradient: 'linear-gradient(135deg, transparent, transparent)',
    solid: 'transparent',
    filmedGradient: 'linear-gradient(90deg, transparent, transparent)',
  },
  none: {
    gradient: 'linear-gradient(135deg, #ffffff, #ffffff)',
    solid: '#ffffff',
    filmedGradient: 'linear-gradient(90deg, #ffffff, #ffffff)',
  },
  // platinum sponsors
  familysearch: {
    gradient: 'linear-gradient(90deg, #355800 8%, #86ba41 92%)',
    solid: '#64BC55',
  },
  ancestry: {
    gradient: 'linear-gradient(90deg, #202122 8%, #353637 92%)',
    solid: '#252525',
  },
  myheritage: {
    gradient: 'linear-gradient(90deg, #892f00 0%, #e46e31 100%)',
    solid: '#EF614C',
  },
  storied: {
    solid: '#EE2541',
  },
  // Sponsor table
  platinum: {
    solid: colors.gray90,
  },
  gold: {
    solid: colors.yellow80,
  },
  silver: {
    solid: colors.gray70,
  },
  bronze: {
    solid: colors.yellow60,
  },
  society: {
    solid: colors.blue80,
  },
  // Authorization Badges
  pending: {
    solid: colors.blue80,
  },
  approved: {
    solid: colors.green80,
  },
  declined: {
    solid: colors.red70,
  },
  accepted: {
    solid: colors.green40,
    textColor: '#ffffff',
  },
  // Schedule Badges
  online: {
    solid: colors.green80,
  },
  beginner: {
    solid: colors.blue80,
  },
  intermediate: {
    solid: colors.red70,
  },
  advanced: {
    solid: colors.purple70,
  },
  alllevels: {
    solid: colors.gray80,
  },
}
const validFlavors = Object.keys(flavors)
const scrimGradient = 'linear-gradient(90deg, #0000008c, 29.69%, transparent 70%)'

export { flavors, validFlavors, scrimGradient }
