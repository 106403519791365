import getIsLowBandwidth from '../lib/helpers/getIsLowBandwidth'

const hasLowBandwidth = getIsLowBandwidth()
const siteConfig = {
  qa4: {
    apiKey: 'OjPYSZ9dA9U5QGgJUY96p9C9d83zw0mt3veoFDl',
    uri: 'https://rootstech.fs-zion-qa4.lower.church.brightspot.cloud/api',
    upload: {
      url: 'https://cms.fs-zion-qa4.lower.church.brightspot.cloud/api/service/rootstech/file-upload-api/',
      key: '7Npkqo9EBRu8GxMopPKu79j7RQ6KAsb6DC7tQhB',
    },
  },
  int: {
    apiKey: 'kktOgVTWL3yBprDpE8TDKGzAG49GXETaf3MUOuq',
    uri: 'https://rootstech.fs-zion-int.lower.church.brightspot.cloud/api',
    upload: {
      url: 'https://cms.fs-zion-int.lower.church.brightspot.cloud/rootstech/api/service/rootstech/file-upload-api/',
      key: '7Npkqo9EBRu8GxMopPKu79j7RQ6KAsb6DC7tQhB',
    },
  },
  cmsZ: {
    apiKey: 'kktOgVTWL3yBprDpE8TDKGzAG49GXETaf3MUOuq',
    uri: 'https://cms-z.api.familysearch.org/rootstech/api',
    upload: {
      key: '7Npkqo9EBRu8GxMopPKu79j7RQ6KAsb6DC7tQhB',
      url: 'https://cms-z.familysearch.org/rootstech/api/service/rootstech/file-upload-api/',
    },
  },
}

const brightspotConfig = {
  imageSizes: {
    // NOTE: Ways to calculate width and height of a 16/9 image.
    // x = 10 -> width = 160, height = 90
    // x = 20 -> width = 320, height = 180
    // x = 30 -> width = 480, height = 270
    // x = 60 -> width = 960, height = 540
    // x = 120 -> width = 1960, height = 1080
    leadThumbnailImage: {
      // 16/9
      leadThumbnailImage_width: hasLowBandwidth ? 480 : 1920,
      leadThumbnailImage_height: hasLowBandwidth ? 270 : 1080,
    },
    leadPromoImage: {
      // 16/9
      leadPromoImage_width: hasLowBandwidth ? 480 : 1920,
      leadPromoImage_height: hasLowBandwidth ? 270 : 1080,
    },
    leadProfileImage: {
      // 3/2
      leadProfileImage_crop: true,
      leadProfileImage_width: hasLowBandwidth ? 480 : 1620,
      leadProfileImage_height: hasLowBandwidth ? 480 : 1080,
    },
    leadImage: {
      // 16/9
      leadImage_width: hasLowBandwidth ? 480 : 1920,
      leadImage_height: hasLowBandwidth ? 270 : 1080,
    },
    expoThumbnailImage: {
      // 16/9
      expoThumbnailImage_width: hasLowBandwidth ? 480 : 960,
      expoThumbnailImage_height: hasLowBandwidth ? 270 : 540,
    },
    graphicsImage: {
      // 16/9
      graphicsImage_width: hasLowBandwidth ? 480 : 960,
      graphicsImage_height: hasLowBandwidth ? 270 : 540,
    },
    profileImage: {
      // 3/2
      profileImage_crop: true,
      profileImage_width: hasLowBandwidth ? 90 : 250,
      profileImage_height: hasLowBandwidth ? 90 : 250,
    },
    imageDetailImage: {
      imageDetail_width: hasLowBandwidth ? 90 : 250,
      imageDetail_height: hasLowBandwidth ? 90 : 250,
    },
    logoImage: {
      // 3/2
      logoImage_crop: true,
      logoImage_width: hasLowBandwidth ? 144 : 288,
      logoImage_height: hasLowBandwidth ? 96 : 192,
    },
    // 16/9
    thumbnailImage: {
      thumbnailImage_width: hasLowBandwidth ? 160 : 512,
      thumbnailImage_height: hasLowBandwidth ? 90 : 288,
    },
    // 16/9
    promoImage: {
      promoImage_width: hasLowBandwidth ? 90 : 512,
      promoImage_height: hasLowBandwidth ? 90 : 288,
    },
  },
  query: {
    defaults: {
      visibilityRequest: { status: '[DRAFT, WORKFLOW, PUBLISHED]' },
      pageRequest: { page: 0, limit: 200 },
      creatorTypes: ['INDIVIDUAL', 'SPONSOR', 'EXHIBITOR'],
      imageSize: 500,
    },
    manualChunkSize: 100, // persisted queries fail if this is at 200
  },
  apiPaths: {
    delivery: '/graphql/delivery/rootstech',
    cae: '/graphql/delivery/conference',
  },
  // meant to match the url hostname
  // there is no local so that we can simulate data in the other lanes
  lane: {
    integration: siteConfig.qa4,
    beta: siteConfig.int,
    www: siteConfig.cmsZ,
  },
  objectTypes: {
    ATTACHMENT: '99ef42fa-b84a-3573-8e5b-015af8a5b9cf',
    CREATOR: 'b8a1b4ae-3cf1-348b-aee1-4b07f5fa6aaf',
    EXHIBITOR: '6df34a33-11b0-33e2-994b-ad547dda06a1',
    IMAGE: '4da1a812-2b2b-36a7-a321-fea9c9594cb9',
    SERIES: 'b6ae2135-13e4-39dd-83cd-9e49f214c4ee',
    SESSION: 'beec5d7a-90c8-32c4-8984-64c80141d536',
    VIDEO: '431a5800-2fb3-3b19-9801-23dc4b0ff9a8',
  },
}

export default brightspotConfig
