import React from 'react'
import { FormCheckbox } from '@fs/zion-form'
import { useTranslation } from 'react-i18next'

export default function AgreePrivacyTermsField() {
  const [t] = useTranslation()

  const privacyTermsText = (
    <>
      {t(
        'conference-announce.subscribe.privacy.terms',
        'I agree to the FamilySearch Terms of Use and have read the Privacy Notice.'
      )}
    </>
  )

  return (
    <FormCheckbox
      name="agreePrivacyTerms"
      label={
        <>
          {privacyTermsText}
          <sup>1</sup>
        </>
      }
      required
    />
  )
}
