import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FormSelect, useZionFormContext } from '@fs/zion-form'
import useCountries from '../../components/dashboard/hooks/useCountries'

export default function CountryField({ disabled, value }) {
  const [t] = useTranslation()
  const { countries } = useCountries()

  const { setValue } = useZionFormContext()

  useEffect(() => {
    if (value && countries) setValue('countries', value)
  }, [countries, setValue, value])

  return (
    <FormSelect
      name="countries"
      label={`${t('form.field.regional-country.label', 'Country or Location')}: *`}
      options={countries}
      disabled={disabled}
    />
  )
}
