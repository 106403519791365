import { useLazyQuery } from '@apollo/client'
import { useCallback } from 'react'
import { searchSessions } from '../../api'
import useErrorHandlerOverlay from '../useErrorHandlerOverlay'

export default function useLazySessionSearch() {
  const [getSessions, { loading, data, error }] = useLazyQuery(searchSessions())
  useErrorHandlerOverlay(error)

  const requestSessions = useCallback(
    async (ids) => {
      const options = {
        variables: {
          ids,
        },
      }
      return getSessions(options)
    },
    [getSessions]
  )

  return [requestSessions, { loading, data: data?.SessionSearch?.results || [], error }]
}
