import React from 'react'
import { ListItem } from '@fs/zion-ui'
// import { format } from '@fs/zion-locale/date-fns'
import { getI18nDate } from '../../index'

export default function PlayerListItem({ session, onClick, playingIdentifier }) {
  const { date, item } = session
  const { title } = item

  return (
    <ListItem
      onClick={onClick}
      overline={
        date
          ? // TODO: when they fix the overline proptypes to allow <time> we can add this back in. https://familysearch.slack.com/archives/CMLL2NGUF/p1686678023449919
            // <time dateTime={format(date, 'yyyy-MM-dd h:MM')}>{getI18nDate(date, { time: true, timezone: true })}</time>
            getI18nDate(date, { time: true, timezone: true })
          : null
      }
      primaryText={
        <>
          {playingIdentifier}
          {title}
        </>
      }
    />
  )
}
