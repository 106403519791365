import PropTypes from 'prop-types'
import React from 'react'
import { Card, WhiteTheme, Row, Separator, Bleed } from '@fs/zion-ui'
import { css } from '@emotion/core'
import { flavors, validFlavors } from '../config'

const rainbowCardCss = css`
  height: 100%;
  &:focus,
  &:focus-within,
  &:active,
  &:hover {
      background: var(--gradient);
    }
  }
`

export default function RainbowHoverCard({ children, flavor = flavors.default, size = 'nano', ...props }) {
  return (
    <Card {...props}>
      <Bleed css={rainbowCardCss} all style={{ '--gradient': flavors[flavor].gradient }}>
        <Separator size={size} />
        <Row alignX="center" alignY="middle">
          <WhiteTheme>{children}</WhiteTheme>
        </Row>
        <Separator size={size} />
      </Bleed>
    </Card>
  )
}

RainbowHoverCard.propTypes = {
  children: PropTypes.node,
  flavor: PropTypes.oneOf(validFlavors),
  size: PropTypes.oneOf(['xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs', 'nano', 'hide']),
}
