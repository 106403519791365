import React from 'react'
import { List, ListItem, H3, LayoutBand, Separator, Divider, FlowGrid, Paragraph, useContainerWidth } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { ResponsiveSeparator } from '../../../lib'
import {
  moreToExploreAlreadyRegisteredSvg,
  moreToExploreBrowseSvg,
  moreToExploreExpoHallSvg,
  moreToExploreFamilyTreeSvg,
  moreToExploreFamousRelativesSvg,
  moreToExploreMemoriesSvg,
  moreToExplorePlaylistsSvg,
  moreToExploreRegionalPageSvg,
  moreToExploreRelativesAtRootstechSvg,
  moreToExploreScheduleSvg,
  moreToExploreSearchSvg,
  moreToExploreSpeakersSvg,
} from '../../../images'

const MoreToExploreCard = ({ subheading, items }) => {
  return (
    <>
      <Paragraph size="sm" secondary>
        {subheading}
      </Paragraph>
      <Divider />
      <List>
        {items.map((item) => (
          <ListItem {...item} emphasized />
        ))}
      </List>
    </>
  )
}

const MoreToExplore = () => {
  const atWidth = useContainerWidth()
  const { t } = useTranslation()

  const moreToExploreHeading = t('home.more-to-explore.heading', 'More to Explore')

  const moreToExploreData = [
    {
      subheading: t('home.more-to-explore.subheading-event', '2025 Event'),
      items: [
        {
          startElement: <ListItem.Image src={moreToExploreScheduleSvg} />,
          primaryText: t('home.more-to-explore.item.primary-text-class-preview', 'Class Preview'),
          to: '/search?f.language=en-US&f.conferenceYear=2025&p.index=0',
          linkName: 'class_preview',
          'data-testid': 'class-preview',
        },
        {
          startElement: <ListItem.Image src={moreToExploreAlreadyRegisteredSvg} />,
          primaryText: t('home.more-to-explore.item.primary-already-registered', 'Already Registered'),
          to: '/registration/prepare',
          linkName: 'already_registered',
          'data-testid': 'already-registered',
        },
        {
          startElement: <ListItem.Image src={moreToExploreSpeakersSvg} />,
          primaryText: t('home.more-to-explore.item.primary-text-see-our-speakers', 'See Our Speakers'),
          to: '/speakers',
          linkName: 'see_our_speakers',
          'data-testid': 'see-our-speakers',
        },
        {
          startElement: <ListItem.Image src={moreToExploreExpoHallSvg} />,
          primaryText: t('home.more-to-explore.item.primary-text-expo-hall', 'Expo Hall'),
          to: '/expohall',
          linkName: 'expo_hall',
          'data-testid': 'expo-hall',
        },
      ],
    },
    {
      subheading: t('home.more-to-explore.subheading-content-library', 'Content Library'),
      items: [
        {
          startElement: <ListItem.Image src={moreToExploreSearchSvg} />,
          primaryText: t('home.more-to-explore.item.primary-text-search', 'Search'),
          to: '/search',
          linkName: 'search',
          'data-testid': 'search',
        },
        {
          startElement: <ListItem.Image src={moreToExplorePlaylistsSvg} />,
          primaryText: t('home.more-to-explore.item.primary-text-my-playlist', 'My Playlist'),
          to: '/playlists',
          linkName: 'my_playlist',
          'data-testid': 'my-playlist',
        },
        {
          startElement: <ListItem.Image src={moreToExploreBrowseSvg} />,
          primaryText: t('home.more-to-explore.item.primary-text-browse-sessions', 'Browse Sessions'),
          to: '/library',
          linkName: 'browse_sessions',
          'data-testid': 'browse-sessions',
        },
        {
          startElement: <ListItem.Image src={moreToExploreRegionalPageSvg} />,
          primaryText: t('home.more-to-explore.item.primary-text-regional-pages', 'Regional Pages'),
          to: '/regions',
          linkName: 'regional_pages',
          'data-testid': 'regional-pages',
        },
      ],
    },
    {
      subheading: t('home.more-to-explore.subheading-connect-with-family-search', 'Connect with FamilySearch'),
      items: [
        {
          startElement: <ListItem.Image src={moreToExploreFamilyTreeSvg} />,
          primaryText: t('home.more-to-explore.item.primary-text-family-tree', 'Build Your Family Tree'),
          to: 'https://www.familysearch.org/tree/guided/connect-to-tree',
          linkName: 'build_your_family_tree',
          'data-testid': 'build-your-family-tree',
          target: '_blank',
        },
        {
          startElement: <ListItem.Image src={moreToExploreRelativesAtRootstechSvg} />,
          primaryText: t('home.more-to-explore.item.primary-text-together', 'Together App'),
          to: 'https://www.familysearch.org/tree/guided/connect-to-tree',
          linkName: 'together_app',
          'data-testid': 'together-app',
          target: '_blank',
        },
        {
          startElement: <ListItem.Image src={moreToExploreFamousRelativesSvg} />,
          primaryText: t('home.more-to-explore.item.primary-text-famous-relatives', 'Famous Relatives'),
          to: 'https://www.familysearch.org/discovery/famousrelatives',
          linkName: 'famous_relatives',
          'data-testid': 'famous-relatives',
          target: '_blank',
        },
        {
          startElement: <ListItem.Image src={moreToExploreMemoriesSvg} />,
          primaryText: t('home.more-to-explore.item.primary-text-surname-discovery', 'Surname Discovery'),
          to: 'https://www.familysearch.org/en/surname',
          linkName: 'surname_discovery',
          'data-testid': 'surname-discovery',
          target: '_blank',
        },
      ],
    },
  ]

  return (
    <>
      <LayoutBand maxWidth="xxl">
        <ResponsiveSeparator />
        <H3 centered>{moreToExploreHeading}</H3>
        <Separator />
        <FlowGrid
          columnCount={atWidth({ default: 1, md: 3 })}
          gutters={atWidth({ default: 'xxs', xs: 'xs', sm: 'sm' })}
        >
          {moreToExploreData.map((data) => (
            <MoreToExploreCard subheading={data.subheading} items={data.items} />
          ))}
        </FlowGrid>
        <ResponsiveSeparator />
      </LayoutBand>
    </>
  )
}

export default MoreToExplore
