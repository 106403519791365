import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { css } from '@emotion/core'
import PossibleLink from '../PossibleLink'

const linkHackCss = css`
  // increase specificity
  &[href][iswhite] {
    border: 0;
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }
`

const StrongLink = forwardRef(function StrongLink({ noUnderline, children, ...props }, ref) {
  return (
    <PossibleLink ref={ref} css={noUnderline ? linkHackCss : undefined} {...props}>
      <strong>{children}</strong>
    </PossibleLink>
  )
})

StrongLink.propTypes = {
  children: PropTypes.string,
}

export default StrongLink
