import React from 'react'
import TabletButton from './TabletButton/TabletButton'
import PillButton from './PillButton'

export default function ChewableButton({ asIconButton, useBillboardColor, children, ...props }) {
  return asIconButton ? (
    <TabletButton title={children} {...props} />
  ) : (
    <PillButton useBillboardColor={useBillboardColor} {...props}>
      {children}
    </PillButton>
  )
}
