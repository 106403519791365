import { useFeatureFlag } from '@fs/zion-flags'
import { i18n } from '@fs/zion-locale'
import { navigationConfig } from '../../config'

const useNavBarItems = () => {
  const siteExperienceFlag = useFeatureFlag('rootstech_site_experience')

  return (
    navigationConfig[siteExperienceFlag.treatment]?.[i18n.language] ??
    navigationConfig[siteExperienceFlag.treatment]?.default ??
    []
  )
}

export default useNavBarItems
