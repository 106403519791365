import { gql } from '@apollo/client'
import { brightspotConfig, conferenceConfig } from '../../config'
import { brEncodeAttribute, stripHtml, escapeBackslash } from '../helpers'
import { DashboardSessionDetail, SessionDetail } from './fragments/SessionDetail'
import { SessionSearch, DashboardSessionSearch } from './fragments/SessionSearch'
import gqlSanitize from './stringUtils'

const { defaults } = brightspotConfig.query
const { logoImage, leadThumbnailImage, profileImage, promoImage, thumbnailImage } = brightspotConfig.imageSizes
export function getSessionDetail() {
  return gql(`
    ${SessionDetail}
    query SessionDetail(
      $pathOrId: ID,
      $leadThumbnailImage_crop: Boolean = false,
      $leadThumbnailImage_height: Int = ${defaults.imageSize},
      $leadThumbnailImage_width: Int = ${defaults.imageSize},
      $promoImage_crop: Boolean = false,
      $promoImage_height: Int = ${defaults.imageSize},
      $promoImage_width: Int = ${defaults.imageSize},
      $logoImage_crop: Boolean = false,
      $logoImage_height: Int = ${defaults.imageSize},
      $logoImage_width: Int = ${defaults.imageSize},
      $thumbnailImage_crop: Boolean = false,
      $thumbnailImage_height: Int = ${defaults.imageSize},
      $thumbnailImage_width: Int = ${defaults.imageSize},
      $profileImage_crop: Boolean = false,
      $profileImage_height: Int = ${defaults.imageSize},
      $profileImage_width: Int = ${defaults.imageSize}) {
        SessionDetail(id: $pathOrId) {
          ...SessionDetail
        }
    }
  `)
}

export function getSessionMutation(isDashboard = false) {
  // NOTE: this needs to stay a mutation until BS fixes the bug where searching for a Session immediately after it
  // is created fails.
  return gql(`
    ${isDashboard ? DashboardSessionDetail : SessionDetail}
    mutation SessionMutation(
      $sessionId: ID,
      $locale: String,
      $leadThumbnailImage_crop: Boolean = false,
      $leadThumbnailImage_height: Int = ${defaults.imageSize},
      $leadThumbnailImage_width: Int = ${defaults.imageSize},
      $logoImage_crop: Boolean = false,
      $logoImage_height: Int = ${defaults.imageSize},
      $logoImage_width: Int = ${defaults.imageSize},
      $promoImage_crop: Boolean = false,
      $promoImage_height: Int = ${defaults.imageSize},
      $promoImage_width: Int = ${defaults.imageSize},
      $thumbnailImage_crop: Boolean = false,
      $thumbnailImage_height: Int = ${defaults.imageSize},
      $thumbnailImage_width: Int = ${defaults.imageSize},
      $profileImage_crop: Boolean = false,
      $profileImage_height: Int = ${defaults.imageSize},
      $profileImage_width: Int = ${defaults.imageSize}) {
        SessionMutation(
          _target: {id: $sessionId, locale: $locale}) {
          result {
            ...${isDashboard ? 'DashboardSessionDetail' : 'SessionDetail'}
          }
        }
      }
  `)
}

export function searchSessions(isDashboard = false) {
  return gql(`
    ${isDashboard ? DashboardSessionSearch : SessionSearch}
    query SessionSearch(
      $creatorIds: [ID],
      $exhibitorIds: [ID],
      $ids: [String],
      $pageRequest: PaginationInput = ${gqlSanitize(defaults.pageRequest)},
      $visibilityRequest: VisibilityInput = ${gqlSanitize(defaults.visibilityRequest)},
      $logoImage_crop: Boolean = false,
      $logoImage_height: Int = ${defaults.imageSize},
      $logoImage_width: Int = ${defaults.imageSize},
      $thumbnailImage_crop: Boolean = false,
      $thumbnailImage_height: Int = ${defaults.imageSize},
      $thumbnailImage_width: Int = ${defaults.imageSize},
      $profileImage_crop: Boolean = false,
      $profileImage_height: Int = ${defaults.imageSize},
      $profileImage_width: Int = ${defaults.imageSize}) {
        SessionSearch(
          creators: $creatorIds,
          exhibitors: $exhibitorIds,
          ids: $ids
          pageRequest: $pageRequest,       
          visibilityRequest: $visibilityRequest) {
            ...${isDashboard ? 'DashboardSessionSearch' : 'SessionSearch'}
        }
      }
  `)
}

export function mutateSession() {
  const query = `
  ${DashboardSessionDetail}
  mutation SessionMutation(
    $classSyllabus: ID,
    $conferenceYear: String,
    $contentLocale: String,
    $coSpeakers: String,
    $countries: [ID],
    $creators: [ID],
    $description: String,
    $exhibitors: [ID],
    $previousSessionLink: String,
    $sessionApplication: String,
    $sessionDifficulty: SessionDifficulty,
    $sessionFlexibility: SessionFlexibility,
    $sessionFormat: SessionFormat,
    $sessionId: String,
    $sessionLocation: sessionLocation,
    $sessionOutline: String,
    $sessionPrerequisites: String,
    $sessionProblem: String,
    $sessionTechnology: SessionTechnology,
    $sessionType: SessionTypeInput,
    $slideDeck: ID,
    $speakerQualifications: String
    $target: MutationTargetInput,
    $tags: [String],
    $title: String,
    $video: ID,
    $slate: slate,
    $workflow: WorkflowInput,
    $leadThumbnailImage_crop: Boolean = false,
    $leadThumbnailImage_height: Int = ${defaults.imageSize},
    $leadThumbnailImage_width: Int = ${defaults.imageSize},
    $logoImage_crop: Boolean = false,
    $logoImage_height: Int = ${defaults.imageSize},
    $logoImage_width: Int = ${defaults.imageSize},
    $promoImage_crop: Boolean = false,
    $promoImage_height: Int = ${defaults.imageSize},
    $promoImage_width: Int = ${defaults.imageSize},
    $thumbnailImage_crop: Boolean = false,
    $thumbnailImage_height: Int = ${defaults.imageSize},
    $thumbnailImage_width: Int = ${defaults.imageSize},
    $profileImage_crop: Boolean = false,
    $profileImage_height: Int = ${defaults.imageSize},
    $profileImage_width: Int = ${defaults.imageSize}) {
      SessionMutation(
        _target: $target,
        _workflow: $workflow,
        classSyllabus: $classSyllabus,
        conferenceYear: $conferenceYear,
        contentLocale: $contentLocale,
        coSpeakers: $coSpeakers,
        countries: $countries,
        creators: $creators,
        description: $description,
        exhibitors: $exhibitors,
        previousSessionLink: $previousSessionLink,
        sessionApplication: $sessionApplication,
        sessionId: $sessionId,
        sessionDifficulty: $sessionDifficulty,
        sessionFlexibility: $sessionFlexibility,
        sessionFormat: $sessionFormat,
        sessionLocation: $sessionLocation,
        sessionOutline: $sessionOutline,
        sessionPrerequisites: $sessionPrerequisites,
        sessionProblem: $sessionProblem,
        sessionTechnology: $sessionTechnology,
        sessionType: $sessionType,
        slideDeck: $slideDeck,
        speakerQualifications: $speakerQualifications,
        tags: $tags,
        title: $title,
        video: $video,
        slate: $slate ) {
          result {
            ...DashboardSessionDetail
          }
        }
    }`

  return gql(query)
}

export async function deleteSession(client, sessionId, contentLocale) {
  // revisit once the new upload endpoint is working correctly
  // deleteArtifacts(sessionId)
  const variables = {
    target: { id: sessionId, locale: contentLocale },
    workflow: { action: 'DELETE' },
  }
  const response = await client.mutate({ mutation: mutateSession(), variables })
  return response?.data?.SessionMutation?.result
}

export async function getSession({ client, sessionId, locale, isDashboard }) {
  const variables = {
    sessionId,
    locale,
    ...logoImage,
    ...leadThumbnailImage,
    ...profileImage,
    ...promoImage,
    ...thumbnailImage,
  }
  const response = await client.mutate({ mutation: getSessionMutation(isDashboard), variables })
  return response?.data?.SessionMutation?.result
}

export function getSessions({ client, params = {} }) {
  const variables = {
    creatorIds: params.creatorIds,
    exhibitorIds: params.exhibitorIds,
  }

  return client.query({ query: searchSessions(), variables })
}

export async function createSession({ client, session }) {
  const variables = {
    classSyllabus: session.syllabusAttachmentId,
    conferenceYear: conferenceConfig.year,
    description: brEncodeAttribute(escapeBackslash(stripHtml(session.description))),
    slideDeck: session.slidesAttachmentId,
    title: escapeBackslash(session.title),
    ...leadThumbnailImage,
    ...logoImage,
    ...profileImage,
    ...promoImage,
    ...thumbnailImage,
  }
  if (session.sessionId) {
    variables.sessionId = session.sessionId
  }
  if (session.sessionType) {
    variables.sessionType = { type: session.sessionType }
  }
  if (session.videoId) {
    variables.video = session.videoId
  }
  if (session.transition && session.transition !== '') {
    variables.workflow = {
      action: 'TRANSITION',
      targetState: session.transition === 'next' ? { next: true } : { state: session.transition },
    }
  }
  if (session.creatorId) {
    variables.creators = [session.creatorId]
  }
  if (session.exhibitorId) {
    variables.exhibitors = [session.exhibitorId]
  }
  // NOTE - 2021-12-02 - This is a hack because BS broke us with their deploy yesterday
  // If we have a session id we are doing an update.  Otherwise, it is a create
  if (session.id) {
    variables.target = { id: session.id, locale: session.locale }
  } else {
    variables.contentLocale = session.locale ? session.locale : ''
  }
  // This is the case when the session is sponsored for a creator
  // If the exhibitor is empty we can safely send the empty string to remove it
  if ((session.exhibitor || session.exhibitor === '') && !session.exhibitorId) {
    variables.exhibitors = [session.exhibitor]
  }
  if (session.slate) {
    variables.slate = session.slate
  }
  if (session.sessionLocation) {
    variables.sessionLocation = session.sessionLocation
  }
  if (session.sessionOutline) {
    variables.sessionOutline = session.sessionOutline
  }
  if (session.sessionProblem) {
    variables.sessionProblem = session.sessionProblem
  }
  if (session.sessionPrerequisites) {
    variables.sessionPrerequisites = session.sessionPrerequisites
  }
  if (session.sessionTechnology) {
    variables.sessionTechnology = session.sessionTechnology
  }
  if (session.sessionDifficulty) {
    variables.sessionDifficulty = session.sessionDifficulty
  }
  if (session.speakerQualifications) {
    variables.speakerQualifications = session.speakerQualifications
  }
  if (session.countries) {
    variables.countries = session.countries
  }
  if (session.previousSessionLink) {
    variables.previousSessionLink = session.previousSessionLink
  }
  if (session.coSpeakers) {
    variables.coSpeakers = session.coSpeakers
  }
  if (session.sessionFormat) {
    variables.sessionFormat = session.sessionFormat
  }
  if (session.sessionFlexibility) {
    variables.sessionFlexibility = session.sessionFlexibility
  }
  if (session.tags) {
    variables.tags = session.tags
  }
  if (session.sessionApplication) {
    variables.sessionApplication = session.sessionApplication
  }

  const response = await client.mutate({ mutation: mutateSession(), variables })
  return response?.data?.SessionMutation?.result
}

export async function updateSession({ client, session }) {
  return createSession({ client, session })
}
