import React from 'react'
import { InfoSheet, H3, Separator, ToggleGroup, Toggle } from '@fs/zion-ui'
import RainbowText from '../RainbowText'
import { useDashboardPermissionsContext } from '../../components/dashboard/providers/DashboardPermissionsProvider'

const ChangePermissionsInfoSheet = ({ flavor, ...infoSheet }) => {
  const { permissions, updatePermissions } = useDashboardPermissionsContext()
  const handleChange = ({ target }) => updatePermissions(target.name, target.checked)

  return (
    <>
      <InfoSheet
        {...infoSheet}
        size="md"
        title={
          <H3 size="H4">
            <RainbowText flavor={flavor}>Change Permissions</RainbowText>
          </H3>
        }
      >
        <Separator />
        <ToggleGroup label="To change your client side permissions, simply select your desired permissions.">
          <Toggle
            label="Speaker"
            name="hasSpeakerAccess"
            checked={permissions.hasSpeakerAccess}
            onChange={handleChange}
          />
          <Toggle
            label="Exhibitor"
            name="hasExhibitorAccess"
            checked={permissions.hasExhibitorAccess}
            onChange={handleChange}
          />
          <Toggle
            label="Sponsor"
            name="hasSponsorAccess"
            checked={permissions.hasSponsorAccess}
            onChange={handleChange}
          />
          <Toggle
            label="Homeland"
            name="hasHomelandAccess"
            checked={permissions.hasHomelandAccess}
            onChange={handleChange}
          />
          <Toggle
            label="Content Admin"
            name="hasContentAdminAccess"
            checked={permissions.hasContentAdminAccess}
            onChange={handleChange}
          />
          <Toggle
            label="Super Admin"
            name="hasSuperAdminAccess"
            checked={permissions.hasSuperAdminAccess}
            onChange={handleChange}
          />
        </ToggleGroup>
      </InfoSheet>
    </>
  )
}

export default ChangePermissionsInfoSheet
