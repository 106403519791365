const floatingChatConfig = {
  ROUTE_BLACKLIST: [
    '/session/*',
    '/special-session/:id',
    '/registered-session',
    '/expohall/:venderId',
    '/dashboard',
    '/call-for-presentations',
    '/vendor-linking/:id',
    '/volunteer',
    '/volunteer/*',
  ],
}

export default floatingChatConfig
