import { Paragraph, LayoutBand, Separator, H2 } from '@fs/zion-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { SocialConnect, getConnectPlatformLinks } from '@fs/zion-social'
import { i18n } from '@fs/zion-locale'
import { useLocation } from '@fs/zion-router'
import { PillButton } from '../buttons'
import RainbowText from '../RainbowText'

const centerCss = css`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const ConferenceUpdatesSection = ({ heading, showSubscribe = true, iconButtonEmphasis = 'low' }) => {
  const [t] = useTranslation()
  const { pathname } = useLocation()

  const enPlatforms = {
    facebook: 'https://www.facebook.com/RootsTech',
    twitter: 'https://twitter.com/RootsTechConf',
    instagram: 'https://www.instagram.com/rootstechofficial/',
    youtube: 'https://www.youtube.com/@familysearch',
  }
  const platforms = i18n.language === 'en' ? enPlatforms : getConnectPlatformLinks(i18n.language)

  return (
    <LayoutBand css={centerCss}>
      <H2 centered>
        <RainbowText flavor="seafoam">
          {heading || t('conference-announce.subscribe.for.updates', 'Subscribe for Updates')}
        </RainbowText>
      </H2>
      <Separator />
      <Paragraph centered>
        <strong>
          {t(
            'after-conference.subscribe.social-channels',
            'Check our social channels for the latest news and information about RootsTech.'
          )}
        </strong>
      </Paragraph>
      {showSubscribe && (
        <>
          <Separator size="md" />
          <PillButton to="/subscription" linkName="Subscribe for Updates" emphasis="medium">
            {t('conference-announce.subscribe.for.updates', 'Subscribe for Updates')}
          </PillButton>
        </>
      )}
      <Separator size="lg" />
      <SocialConnect
        platforms={platforms}
        useIconButtons
        iconButtonEmphasis={iconButtonEmphasis}
        analytics={{
          link_name: `connect: subscribe for updates`,
          link_page: `${pathname}`,
          link_app: 'rootstech',
        }}
      />
    </LayoutBand>
  )
}

export default ConferenceUpdatesSection
