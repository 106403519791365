import { useLocation } from '@fs/zion-router'
import zionDebug from '@fs/zion-debug'
import Cookies from 'js-cookie'

const debug = zionDebug('usePreviewFlags')

export default function usePreviewFlags() {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const flagOverride = params.get('preview') !== null
  const cookieOverrides = Cookies.get('fs_zionFlags')?.split(',') ?? []
  const cookieOverride = cookieOverrides?.includes('rootstech_preview')
  // This requires a pre-release build of zion-flags
  if (flagOverride && !cookieOverride) {
    debug('preview flag is on, cookie is not, so setting cookie')
    cookieOverrides.push('rootstech_preview')
    Cookies.set('fs_zionFlags', cookieOverrides.join(','))
  }
}
