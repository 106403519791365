import React from 'react'
import { FormCheckbox } from '@fs/zion-form'
import { useTranslation } from 'react-i18next'

export default function ReceiveMessagesField() {
  const [t] = useTranslation()
  return (
    <FormCheckbox
      name="receiveMessages"
      label={t(
        'conference-announce.subscribe.receive-messages',
        'I would like to receive messages from RootsTech by FamilySearch, including news about future events to the email address or mobile number I provided.'
      )}
    />
  )
}
