import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { trackLink } from '@fs/zion-analytics'
import { ContentAdd, ContentCheck } from '@fs/zion-icon'
import { useUser } from '@fs/zion-user'
import { useHistory } from '@fs/zion-router'
import { useStatusOverlay } from '@fs/zion-ui'
import { useFeatureFlag } from '@fs/zion-flags'
import { useLoggedOutOverlay, usePlaylistsContext, usePlaylistsContextV2 } from '../../providers'
import ChewableButton from './ChewableButton'
import notifySentry from '../helpers/sentryNotifier'

const EMPHASIS = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
  SPECIAL: 'special',
}

export default function FollowSeriesButton({ seriesId, asIconButton, size, ...props }) {
  const [t] = useTranslation()
  const user = useUser()
  const improvedUxEnabled = useFeatureFlag('rootstech_playlists_improved_ux').isOn
  const { followPlaylist: followPlaylistV1, series } = usePlaylistsContext()
  const { followPlaylist: followPlaylistV2, deletePlaylist, isFollowing, playlists } = usePlaylistsContextV2()
  const showStatusOverlay = useStatusOverlay()
  const history = useHistory()
  const { overlay: loggedOutOverlay } = useLoggedOutOverlay({
    addToPlaylist: seriesId,
    playlistItemType: 'session',
  })
  const [following, setFollowing] = useState(false)
  useEffect(() => {
    if (playlists) {
      setFollowing(improvedUxEnabled ? isFollowing(seriesId) : series.some((s) => s.parent.id === seriesId))
    }
  }, [improvedUxEnabled, isFollowing, playlists, series, seriesId])

  const onClickV1 = (e) => {
    const loggedIn = user?.signedIn ?? false
    if (!loggedIn) {
      trackLink({ name: 'Playlists: logged out', action: 'click_action' })
      loggedOutOverlay?.handleClick?.(e)
    } else {
      trackLink({ name: 'Playlists: logged in', action: 'click_action' })
      followPlaylistV1({ seriesId }).then((data) => {
        const playlistId = data.id

        showStatusOverlay({
          message: t('playlists.series.saved.alert', 'Following in playlists.'),
          type: 'success',
          Icon: ContentCheck,
          actionLabel: t('playlists.saved.nav-to-playlist', 'Go to Playlist'),
          actionCallback: () => history.push(`/playlists/${playlistId}`),
        })
      })
    }
  }

  const onClickV2 = useCallback(
    async (e) => {
      const loggedIn = user?.signedIn ?? false
      if (!loggedIn) {
        trackLink({ name: 'Playlists: logged out', action: 'click_action' })
        loggedOutOverlay?.handleClick?.(e)
      } else if (!following) {
        trackLink({ name: 'Playlists: logged in', action: 'follow_series' })
        try {
          const { id: playlistId, name } = await followPlaylistV2(seriesId)
          setFollowing(true)

          showStatusOverlay({
            message: t('playlists.alerts.now-following', 'Following { name }.', { name }),
            type: 'success',
            Icon: ContentCheck,
            actionLabel: t('playlists.saved.nav-to-playlist', 'Go to Playlist'),
            actionCallback: () => history.push(`/playlists/${playlistId}`),
          })
        } catch (err) {
          showStatusOverlay({
            message: t(
              'playlists.errors.generic.follow',
              'We’re sorry, but there was an issue following this series. Please try again shortly while we investigate.'
            ),
            type: 'error',
            dismissable: true,
          })
          notifySentry({ ...err.response, err })
        }
      } else {
        trackLink({ name: 'Playlists: logged in', action: 'unfollow_series' })
        const { id } = [...playlists.values()].find((p) => p.parent?.id === seriesId)
        await deletePlaylist(id)
        setFollowing(false)
      }
    },
    [
      deletePlaylist,
      followPlaylistV2,
      following,
      history,
      loggedOutOverlay,
      playlists,
      seriesId,
      showStatusOverlay,
      t,
      user?.signedIn,
    ]
  )

  const label = following
    ? t('playlists.series.following.label', 'Following')
    : t('playlists.series.follow.label', 'Follow')

  return (
    <>
      <ChewableButton
        asIconButton={asIconButton}
        size={size}
        onClick={improvedUxEnabled ? onClickV2 : onClickV1}
        Icon={following ? ContentCheck : ContentAdd}
        emphasis={EMPHASIS.LOW}
        data-testid="playlists-series"
        {...props}
      >
        {label}
      </ChewableButton>
    </>
  )
}
