import React, { Children } from 'react'
import { Paragraph, Row, Separator, H3, Subheading } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'

export default function NoResults({ actions }) {
  const [t] = useTranslation()
  const listItems = [
    t('search.no-results.item1', 'Try more general words'),
    t('search.no-results.item2', 'Try different words that mean the same thing'),
    t('search.no-results.item3', 'Check your spelling'),
  ]

  return (
    <div style={{ margin: '0 auto' }}>
      <H3 size="H5" centered>
        {t('search.no-results.heading', 'Sorry, No Results Were Found')}
        <Subheading>{t('search.no-results.body', 'Try your search again with these suggestions:')}</Subheading>
      </H3>
      <Separator />
      <Paragraph size="sm" as="ul">
        {Children.toArray(listItems.map((item) => <li>{item}</li>))}
      </Paragraph>
      <Separator />
      <Row alignX="center">{actions}</Row>
    </div>
  )
}
