import { Link } from '@fs/zion-router'
import React, { forwardRef } from 'react'
import { softSanitizeProps } from '../../helpers'

export default forwardRef(function PossibleLink({ to, ...props }, ref) {
  if (to) {
    return <Link ref={ref} to={to} {...props} />
  }
  const sanitizedProps = softSanitizeProps(props, ['linkName', 'external'])
  return <span ref={ref} {...sanitizedProps} />
})
