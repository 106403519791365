import React, { createContext, useContext, useState } from 'react'

const CountryContext = createContext()
export const useCountryChange = () => useContext(CountryContext)

export const CountryProvider = ({ children }) => {
  const [country, setCountry] = useState()

  return (
    <CountryContext.Provider
      value={{
        country,
        setCountry,
      }}
    >
      {children}
    </CountryContext.Provider>
  )
}
