import { useEffect, useState } from 'react'
import { useUser } from '@fs/zion-user'
import { getVideoIds, injectWatchHistory } from './helpers'
import { getHistoryEntries } from '../../../api'

export default function useVideoWatchProgress({ loading, data }) {
  const [state, setState] = useState(data)
  const { signedIn } = useUser()
  const [historyEntries, setHistoryEntries] = useState([])

  useEffect(() => {
    if (!loading) {
      // if there is no watch history, just return the data
      setState(data)
      if (signedIn) {
        const ids = getVideoIds(data)
        if (ids.length > 0) {
          getHistoryEntries(ids).then(setHistoryEntries)
        }
      }
    }
  }, [data, loading, signedIn])

  useEffect(() => {
    if (loading || historyEntries.length === 0) {
      return
    }

    const injectedData = injectWatchHistory(data, historyEntries)
    setState(injectedData)
  }, [data, historyEntries, loading])

  return { data: state, loading }
}
