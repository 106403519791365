import { useEffect, useState } from 'react'
import { CALENDAR_MAIN_STAGE, CALENDAR_SESSIONS, SESSION_LOCATION } from '../helpers/scheduleHelpers'
import useCalendarData from './useCalendarData'
import { roundTimestampDown } from '../../../lib'

export default function useScheduleData() {
  const [state, setState] = useState([])
  const { loading: mainStageloading, data: mainStageData } = useCalendarData({ calendarType: CALENDAR_MAIN_STAGE })
  const { loading: sessionsloading, data: sessionsData } = useCalendarData({ calendarType: CALENDAR_SESSIONS })

  const loading = mainStageloading || sessionsloading
  useEffect(() => {
    if (!loading) {
      const data = [...(mainStageData ?? []), ...(sessionsData ?? [])]
        // BS saves seconds when a session is created. This rounds down the timestamps to the nearest minute so the sessions always start at 00 seconds
        .map(({ date, ...rest }) => ({
          date: roundTimestampDown({ to: 'minute', time: date }),
          ...rest,
        }))
        .sort((a, b) => a.date - b.date)
        // Filter the duplicates by timestamp and ID.
        .filter(
          (calendarItem, idx, arr) =>
            calendarItem?.item &&
            arr.findIndex(
              ({ date, item }) => date === calendarItem.date && item?.contentId === calendarItem?.item?.contentId
            ) === idx
        )
        // Remove In Person location from rebroadcasts.
        .map((calendarItem, idx, arr) => {
          // If the current item has a duplicate that is scheduled earlier, override the sessionLocation to be Online.
          if (
            arr.some(
              ({ date, item }, index) =>
                date < calendarItem.date && item?.contentId === calendarItem?.item?.contentId && index !== idx
            )
          ) {
            const { date, item } = calendarItem
            return { date, item: { ...item, sessionLocation: SESSION_LOCATION.online } }
          }
          return calendarItem
        })

      setState(data)
    }
  }, [loading, mainStageData, sessionsData])

  return { loading, data: state }
}
