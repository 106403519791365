import React from 'react'
import { CardGroup } from './card'
import { SeeMoreButton } from './search'

export default function DisplaySearchResults({ results, getMore, label, noCrop, columnCount = 4 }) {
  if (!results) return null

  return (
    <>
      {results?.length > 0 && (
        <CardGroup heading={label} items={results} maxColumnCount={columnCount} noCrop={noCrop} />
      )}
      <SeeMoreButton onClick={getMore} />
    </>
  )
}
