import React, { useEffect } from 'react'
import { i18n } from '@fs/zion-locale'
import { useStatusOverlay, colors } from '@fs/zion-ui'
import useScript from 'react-script-hook'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import zionDebug from '@fs/zion-debug'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from './LoadingSpinner'

const debug = zionDebug('rootstech:MarketoForm')

const formOverrideCss = css`
  width: 100% !important;
  padding: 0px !important;
  color: #ffffff !important;
  & .mktoHasWidth {
    width: 100% !important;
  }
  & .mktoGutter {
    display: none;
  }
  & .mktoAsterix {
    grid-area: b;
    color: #ffffff !important;
  }
  & .mktoRadioList {
    margin-bottom: 24px !important;
    border: none !important;
    padding-block-start: 0 !important;
    padding-block-end: 0 !important;
    padding-inline-start: 0 !important;
    padding-inline-end: 0 !important;
    margin-inline-start: 0 !important;
    margin-inline-end: 0 !important;
  }
  & .mktoLabel {
    font-family: Verdana, Ayuthaya, 'PingFang SC Light', 'PingFang TC Light', 'PingFang-SC-Light', 'PingFang-TC-Light',
      'PingFang SC', 'PingFang TC', 'Hiragino Sans GB', 'Lantinghei TC Extralight', 'Lantinghei SC Extralight',
      FZLTXHB--B51-0, FZLTZHK--GBK1-0, 'Heiti SC Light', STHeitiSC-Light, 'Heiti SC', 'Heiti TC Light', STHeitiTC-Light,
      'Heiti TC', 'Microsoft Yahei', 'Microsoft Jhenghei', 'Noto Sans CJK KR', 'Noto Sans CJK JP', 'Noto Sans CJK SC',
      'Noto Sans CJK TC', 'Source Han Sans K', 'Source Han Sans KR', 'Source Han Sans JP', 'Source Han Sans CN',
      'Source Han Sans HK', 'Source Han Sans TW', 'Source Han Sans TWHK', 'Droid Sans Fallback', 'HanaMinBFont',
      sans-serif;
    margin: 0 !important;
    font-size: 12px !important;
    word-break: break-word !important;
    color: var(--special-type-color, ${colors.gray100}) !important;
    line-height: 20px !important;
    padding-top: 5px !important;
    padding-bottom: 3px !important;
    letter-spacing: 0.004em !important;
    width: 100%;
    overflow: hidden;
    -webkit-box-orient: vertical;
    color: var(--special-type-color, ${colors.gray100});
    cursor: inherit;
    display: grid;
    grid-template-areas: 'a b';
    grid-template-columns: max-content;
    grid-gap: 4px;
    font-weight: bold;
  }

  & .mktoField,
  & .mktoFieldDescriptor,
  & .mktoFieldWrap {
    width: 100% !important;
  }

  & .mktoCheckboxList {
    display: flex;
    gap: 5px;
    position: relative;
    top: -25px;
  }

  & .mktoField {
    padding: 0 8px 1px !important;
    display: block !important;
    box-shadow: none !important;
    font-size: 14px !important;
    font-family: inherit !important;
    flex-grow: 2 !important;
    border-radius: 4px !important;
    border: solid 1px ${colors.gray40} !important;
    background: ${colors.gray00} !important;
    cursor: text !important;
    color: #ffffff !important;
    height: 32px !important;
    max-width: 100% !important;
    -webkit-appearance: auto !important;
  }
  /* & .mktoField[type='radio'] {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: inset 0 0 0 1px ${colors.gray100};
    background: ${colors.gray02};
    border-radius: 50%;
    cursor: inherit;
    height: 20px;
   margin-top: 0; margin-inline-end: 8px; margin-bottom: 0; margin-inline-start: 0;
    width: 20px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  } */
  & .mktoField:focus-within,
  & .mktoField:focus-visible {
    outline: none !important;
    border-width: 2px !important;
    border-color: ${colors.blue30} !important;
  }
  & input[type='radio'].mktoField {
    width: auto !important;
    height: auto !important;
  }
  & input[type='checkbox'].mktoField {
    width: 20px !important;
    height: 20px !important;
  }
  & .mktoTextField {
    &::placeholder {
      color: ${colors.transparent.gray30};
    }
  }

  & textarea.mktoField {
    height: auto !important;
    border-radius: 4px;
    font-size: 16px;
    font-family: inherit;
    line-height: 28px !important;
    padding: 4px 8px; /* 5px padding offsets the text so it lines up with the grid */
    width: 100%;
    border: none;
    background: transparent;
    resize: vertical;
    color: ${colors.gray100};
    min-height: 36px;

    &::placeholder {
      color: ${colors.transparent.gray30};
    }

    &:disabled {
      resize: none;
      cursor: not-allowed;
    }

    &:focus {
      outline: none;
    }

    &[is-multiline] {
      height: 36px; /* Height is 40 when adding the borders from the container in InputBase */
      overflow-y: auto;
    }

    &[disable-resize] {
      resize: none;
    }
  }
  & .mktoError {
    position: initial !important;
    inset-inline-end: unset !important;
    bottom: -29px !important;
  }
  & .mktoErrorMsg {
    background-color: transparent !important;
    background-image: none !important;
    border: 0px !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    padding-top: 58px !important;
    color: #b00504 !important;
    text-shadow: none !important;
    line-height: 40px !important;
  }
  & .mktoErrorArrowWrap {
    display: none !important;
  }

  & .mktoButtonRow {
    display: table !important;
    margin-inline-start: auto !important;
  }

  & .mktoButtonWrap {
    --black: black;
    --white: #ffffff;
    --hover: #fff3;
    --active: #fff4;

    border: unset !important;
    margin: 0 !important;
    box-sizing: border-box !important;
    min-height: 44px !important;
    border-radius: 22px !important;
    border: solid 1px ${colors.offwhite} !important;
    padding: 0 24px !important;
    color: ${colors.offwhite} !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.2s;
    min-width: 0;
    display: flex;
    align-items: center;

    background: #ffffff !important;
    border-color: ${colors.offwhite} !important;
    &:not([disabled]) {
      color: ${colors.almostBlack} !important; // When it's a Link tag, it tries to overwrite the color
      &:hover {
        filter: brightness(0.8) !important;
      }
      &:active {
        filter: brightness(0.9) !important;
      }
    }

    &[disabled],
    &[disabled]:hover {
      background: ${colors.gray05};
      color: ${colors.gray40};
      border-color: var(--pb-disabled-border-color, ${colors.gray40});
      cursor: not-allowed;
    }

    &[no-border] {
      border: none;
      font-weight: 400;
    }

    /* This is to disable hidden overflow on the icon so we can position is correctly */
    & div[data-shape] {
      margin-inline-end: var(--pb-icon-margin-right, 8px);
      margin-inline-start: var(--pb-icon-margin-left, -8px);
    }

    &[no-wrap] {
      white-space: nowrap;
    }
  }
  & .mktoButton {
    border: 0px !important;
    background-color: transparent !important;
    background-image: none !important;
    color: black !important;
    font-weight: bolder !important;
    text-shadow: unset !important;
  }
  & hr {
    height: 2px !important;
    width: 115% !important;
    margin: 0px !important;
    background: linear-gradient(135deg, #C31A86 8%, #933FA0 50%, #00ADEE 92%);
    border: 0;
  }
  & .mktoHtmlText > span:first-of-type {
    font-size: 14px !important;
  }
  a {
    color: ${colors.blue60};
    text-decoration: none;

    &:hover[class='Link'] {
      text-decoration: underline;
    }

    &:visited[class='Link'] {
      color: ${colors.red60};
    }
  }
`

const FORMS_URL = 'https://mklp.familysearch.org/js/forms2/js/forms2.min.js'
const FORMS_DOMAIN = 'https://mklp.familysearch.org'
const FORMS_ACCOUNT = '590-BUK-726'

export default function MarketoForm({ languageForms }) {
  debug(`Language: ${i18n.language}`)
  const showStatusOverlay = useStatusOverlay()
  const [t] = useTranslation()

  const formId = Object.keys(languageForms).includes(i18n.language) ? languageForms[i18n.language] : languageForms.en

  const [loading, error] = useScript({
    src: FORMS_URL,
    onload: () => {
      debug(`Loading Marketo Form: ${formId}`)
      window?.MktoForms2?.loadForm(FORMS_DOMAIN, FORMS_ACCOUNT, formId)
    },
  })

  useEffect(() => {
    if (error) {
      showStatusOverlay({
        message: error ?? t('page.api.error', 'Error Occurred, Please Try Again Later.'),
        type: 'error',
        dismissable: true,
      })
      debug(`Error: ${error}`)
    }
  }, [error, showStatusOverlay, t])

  return loading ? (
    <LoadingSpinner />
  ) : (
    <form css={formOverrideCss} id={`mktoForm_${formId}`} data-testid="external-form" />
  )
}

MarketoForm.propTypes = {
  languageForms: PropTypes.shape({
    en: PropTypes.number,
    fr: PropTypes.number,
    es: PropTypes.number,
    pt: PropTypes.number,
    it: PropTypes.number,
    ru: PropTypes.number,
    zh: PropTypes.number,
    'zh-hans': PropTypes.number,
    'zh-hant': PropTypes.number,
    ja: PropTypes.number,
    de: PropTypes.number,
    ko: PropTypes.number,
  }).isRequired,
}
