import axios from '@fs/zion-axios'
import SERVICE from './serviceName'

// NOTE: This service was deleted. We will need to port this to the new service when ready. Work should start in July.
// DOCS: https://fhconfluence.churchofjesuschrist.org/x/yaP_E

const BASE = `${SERVICE}/playlists`
const config = { headers: { 'Content-Type': 'application/json' }, data: {} }
export const getPlaylistVisibility = async ({ playlistId }) => {
  return axios.get(`${BASE}/${playlistId}/visibility`, config)
}

export const updatePlaylistVisibility = async ({ playlistId, data }) => {
  return axios.put(`${BASE}/${playlistId}/visibility`, data, config)
}

export const getPlaylistItemsOrder = async ({ playlistId }) => {
  return axios.get(`${BASE}/${playlistId}/order`, config)
}

export const updatePlaylistItemsOrder = async ({ playlistId, ordering }) => {
  return axios.put(`${BASE}/${playlistId}/order`, ordering, config)
}

export const getPlaylistName = async ({ playlistId }) => {
  return axios.get(`${BASE}/${playlistId}/name`, config)
}

export const updatePlaylistName = async ({ playlistId, data }) => {
  return axios.put(`${BASE}/${playlistId}/name`, data, config)
}

export const updatePlaylist = async ({ playlistId, data }) => {
  return axios.put(`${BASE}/${playlistId}`, data, config)
}

export const getPlaylistItems = async ({ playlistId }) => {
  return axios.get(`${BASE}/${playlistId}/items`, config)
}

export const addPlaylistItem = async ({ playlistId, sessionId }) => {
  return axios.put(`${BASE}/${playlistId}/items/${sessionId}`, { id: sessionId }, config)
}

export const addMultipleItemsToPlaylist = async ({ playlistId, sessionIds }) => {
  return axios.post(`${BASE}/${playlistId}/items`, sessionIds, config)
}

export const removePlaylistItem = async ({ playlistId, sessionId, data }) => {
  return axios.delete(`${BASE}/${playlistId}/items/${sessionId}`, data, config)
}

export const getPlaylists = async (offset) => {
  return axios.get(`${BASE}/`, { ...config, params: offset ? { offset } : {} })
}

export const getPlaylistsForItem = async ({ sessionId, signedIn } = {}) => {
  if (signedIn) {
    return axios.get(`${BASE}/`, { ...config, params: { itemId: sessionId, includeItems: false } })
  }
  return { data: { results: [] } }
}

export const createPlaylist = async ({ data }) => {
  return axios.post(`${BASE}/`, { ...data, method: 'new' }, config)
}

export const followPlaylist = async ({ seriesId }) => {
  return axios.post(`${BASE}/`, { sourcePlaylist: seriesId, method: 'follow' }, config)
}

export const getPlaylist = async ({ playlistId, includeItems = true }) => {
  return axios.get(`${BASE}/${playlistId}`, { ...config, params: { includeItems } })
}

export const deletePlaylist = async ({ playlistId }) => {
  return axios.delete(`${BASE}/${playlistId}`, config)
}

export const getDefaultPlaylist = async ({ includeItems = true } = {}) => {
  return axios.get(`${BASE}/default`, { ...config, params: { includeItems } })
}
