import React from 'react'
import { LayoutBand, Row, Separator, H2 } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { useFeatureFlag } from '@fs/zion-flags'
import CountDown from './CountDown'
import countdownTimerBackgroundJpg from '../../images/countdown-timer-background.jpg'
import { conferenceConfig } from '../../config'
import { useIsMdWidth } from '../../hooks'

const CountDownBanner = () => {
  const [t] = useTranslation()
  const siteExperienceFlag = useFeatureFlag('rootstech_site_experience')
  const isMdWidth = useIsMdWidth()

  if (siteExperienceFlag.treatment !== 'countdown') return null

  return (
    <LayoutBand image={countdownTimerBackgroundJpg}>
      <Separator size="xs" />
      <Row alignX="center" alignY="middle" wrap>
        <H2 supplementary centered={isMdWidth}>
          {t('homepage.countdown.begins', 'Conference Begins:')}
        </H2>
        <CountDown startTimestamp={conferenceConfig.startTimestamp} noReload />
      </Row>
      <Separator />
    </LayoutBand>
  )
}

export default CountDownBanner
