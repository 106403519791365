import React from 'react'
import { css } from '@emotion/core'
import { LayoutBand, Paragraph, Separator, useContainerWidth, H2 } from '@fs/zion-ui'
import PropTypes from 'prop-types'
import { PillButton } from './buttons'
import { flavors, validFlavors } from '../config'
import RainbowText from './RainbowText'

const containerCss = css`
  background-size: contain;
  background-position-y: top;
  background-repeat: no-repeat !important;
`

/**
 * @function HeroImage **creates page-width HeroImage component with optional descriptive text & button**
 * @param {string} [image=''] Background image
 * @param {number} [minHeight=600] Minimum height of HeroImage. Default `600`
 * @param {string} [headingSize='sm'] Size of optional Rainbow header text. Default `'sm'`
 * @param {string|Object} [heading=''] Optional Rainbow header text (use JSX object for custom text)
 * @param {string} [flavor='blueberry'] Gradient flavor of heading. Default `'blueberry'`
 * @param {string} [description=''] Optional descriptive text
 * @param {string} [buttonLabel=''] Optional button label. Req'd to render button
 * @param {string} [to='/'] Optional button link. Default `'/'`
 * @param {number} [maxHeaderWidth=350] Max-width of header text. Default `350`
 * @param {string} [align='center'] Alignment format for optional text. Default `'center'`
 * @returns {object} HeroImage component
 */
export default function HeroImage({
  image = '',
  minHeight = 700,
  heading = '', // can take a custom JSX object, tho React doesn't like it
  flavor = flavors.default,
  description = '',
  buttonLabel = '',
  to = '/',
  align = 'center',
}) {
  const atWidth = useContainerWidth()
  let y = 'center'
  let x = 'center'
  switch (align) {
    case 'topLeft':
      y = 'flex-start'
      x = 'left'
      break
    case 'top':
      y = 'flex-start'
      break
    case 'topRight':
      y = 'flex-start'
      x = 'right'
      break
    case 'left':
      x = 'left'
      break
    case 'center':
      break
    case 'right':
      x = 'right'
      break
    case 'bottomLeft':
      y = 'flex-end'
      x = 'left'
      break
    case 'bottom':
      y = 'flex-end'
      break
    case 'bottomRight':
      y = 'flex-end'
      x = 'right'
      break
    default:
      break
  }

  return (
    <LayoutBand
      data-testid="imageContent"
      minHeight={atWidth({ lg: minHeight, md: 500, xs: 350, default: 350 })}
      image={image}
      style={{ margin: 'auto', justifyContent: y }}
      css={containerCss}
    >
      <div style={{ width: '100%', textAlign: x }}>
        <div data-testid="textContent" style={{ maxWidth: '500px', display: 'inline-block' }}>
          {heading && (
            <H2 supplementary>
              <RainbowText flavor={flavor}>{heading}</RainbowText>
            </H2>
          )}
          <Separator size="xs" />
          {description && <Paragraph size="sm">{description}</Paragraph>}
          <Separator size="xxs" />
          {buttonLabel && (
            <PillButton to={to} linkName="Hero: Button">
              {buttonLabel}
            </PillButton>
          )}
        </div>
      </div>
    </LayoutBand>
  )
}

HeroImage.propTypes = {
  image: PropTypes.string,
  minHeight: PropTypes.number,
  // disabled forbid-prop-types so heading can be used as string or JSX
  // eslint-disable-next-line react/forbid-prop-types
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  flavor: PropTypes.oneOf(validFlavors),
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  to: PropTypes.string,
  align: PropTypes.oneOf([
    'topLeft',
    'top',
    'topRight',
    'left',
    'center',
    'right',
    'bottomLeft',
    'bottom',
    'bottomRight',
  ]),
}
