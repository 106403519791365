const helpConfig = {
  researchHelp: {
    de: 'https://go.oncehub.com/deutsche',
    en: 'https://go.oncehub.com/ResearchStrategySession',
    es: 'https://go.oncehub.com/consultaenlinea',
    fr: 'https://go.oncehub.com/consultationsenligne',
    it: 'https://go.oncehub.com/consulenzevirtuali',
    ja: '',
    ko:
      'https://www.familysearch.org/ko/fieldops/apac-%EC%9A%B0%EB%A6%AC%EC%97%90%EA%B2%8C-%EC%97%B0%EB%9D%BD%ED%95%98%EA%B8%B0-%EB%B6%80%EB%A6%84-%EC%9D%BC%EC%A0%95-%EA%B3%84%ED%9A%8D%ED%95%98%EA%B8%B0',
    mn: '',
    nl: 'https://go.oncehub.com/onlineconsultaties',
    pt: 'https://go.oncehub.com/consultasvirtuais',
    ru: '',
    th: 'https://go.oncehub.com/ResearchStrategySessionsThai',
    zh: 'https://go.oncehub.com/ResearchStrategySessionMandarin',
    'zh-hans': 'https://go.oncehub.com/ResearchStrategySessionMandarin',
  },
}

export default helpConfig
