// https://fhconfluence.churchofjesuschrist.org/x/vB8nE
import axios from '@fs/zion-axios'
import SERVICE from './serviceName'
import { chunkArray } from '../../lib/helpers/functionalHelpers'

const BASE = `${SERVICE}/watch-history/`
const ENTRIES = `${BASE}entries`
const ENABLED = `${BASE}enabled`
// The backend is also limited to 50
const CHUNK_LIMIT = 50

// GET
export const getWatchHistory = async () => axios.get(BASE)
export const getHistoryEntryForSession = async (sessionId) => axios.get(`${ENTRIES}/${sessionId}`)
export const getWatchHistoryEnabled = async () => axios.get(ENABLED)

const makeHistoryEntriesRequest = async ({ sessionIds = [], count = CHUNK_LIMIT, offset = null }) => {
  return axios.get(`${ENTRIES}?count=${count}&offset=${offset}&${sessionIds.map((id) => `sid=${id}`).join('&')}`)
}
export const getSomeHistoryEntries = (options) => makeHistoryEntriesRequest(options)

export const getHistoryEntries = async (sessionIds = []) => {
  const chunkedRequests = chunkArray(sessionIds, CHUNK_LIMIT)
  const requests = chunkedRequests.map((ids) => makeHistoryEntriesRequest({ sessionIds: ids }))
  const responses = await Promise.all(requests)
  const results = responses.map(({ data }) => data.results)
  return results.flat()
}

// DELETE
export const removeHistoryEntryForSession = async (sessionId) => axios.delete(`${ENTRIES}/${sessionId}`)
export const purgeWatchHistory = async () => axios.delete(BASE)

// PUT
export const setWatchHistoryEnabled = async (shouldEnable) => axios.put(ENABLED, { enabled: shouldEnable })

// POST
export const addWatchHistoryEntry = async ({ id, seekTime, mostRecentPosition, completed }) =>
  axios.post(ENTRIES, { sessionId: id, mostRecentPosition: mostRecentPosition ?? seekTime, completed })

export default function useWatchHistoryApi() {
  return {
    getWatchHistory,
    getHistoryEntryForSession,
    getHistoryEntries,
    addWatchHistoryEntry,
    setWatchHistoryEnabled,
    removeHistoryEntryForSession,
    purgeWatchHistory,
  }
}
