import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useLocation } from '@fs/zion-router'
import { useOverlay } from '@fs/zion-ui'
import { useUser } from '@fs/zion-user'
import zionDebug from '@fs/zion-debug'
import { LoggedOutOverlay } from '../lib/overlays'

const debug = zionDebug('rootstech:auth')
export const LoggedOutContext = createContext()
export const useLoggedOutOverlay = (providedQuery) => {
  const { overlay, query, setQuery, ...rest } = useContext(LoggedOutContext)
  const user = useUser()

  const handleClick = useCallback(
    (event) => {
      if (user.signedIn) {
        debug('(LoggedOutOverlayProvider) tried to open logged out overlay when user was already signed in')
        return
      }
      if (providedQuery) {
        if (providedQuery instanceof Function) {
          setQuery(providedQuery())
        } else {
          setQuery(providedQuery)
        }
        if (!overlay?.isOpen || providedQuery === query) {
          overlay?.handleClick(event)
        }
      } else {
        overlay?.handleClick(event)
      }
    },
    [setQuery, query, overlay, providedQuery, user]
  )

  useEffect(() => {
    if (!overlay.isOpen) {
      setQuery(null)
    }
  }, [overlay.isOpen, setQuery])

  return { overlay: overlay && { ...overlay, handleClick }, query, ...rest }
}

export function LoggedOutOverlayProvider({ children }) {
  const { pathname } = useLocation()
  const backPaths = ['/playlist', '/registered-session/']
  const guestRedirect = backPaths.includes(pathname) ? '/back' : ''
  const overlay = useOverlay()
  const [query, setQuery] = useState()
  const [listItems, setListItems] = useState([])

  return (
    <LoggedOutContext.Provider value={{ overlay, query, setQuery, listItems, setListItems }}>
      {children}
      {(overlay.isOpen || overlay.transitioning) && <LoggedOutOverlay {...overlay} guestRedirect={guestRedirect} />}
    </LoggedOutContext.Provider>
  )
}
