const second = 1 * 1000
const minute = 60 * second
const hour = 60 * minute
const day = 24 * hour

const conferenceConfig = {
  startTimestamp: 1741215600000, // Wed Mar 5 2025 16:00:00 GMT-0700 (Mountain Standard Time)
  endTimestamp: 1741395600000, // Sat Mar 7 2025 18:00:00 GMT-0700 (Mountain Standard Time)
  year: 2025,

  cfcSubmissionDeadlineTimestamp: 1719791999000, // Sunday, June 30, 2024 11:59:59 PM GMT-06:00 DST
  cfcApprovalDeadlineTimestamp: 1724263140000, // Wednesday, August 21, 2024 11:59:00 AM GMT-06:00 DST
  earlyBirdEndTimestamp: '2024-10-31T07:00:00.000', // 11:59PM
  blackFridayEndTimestamp: '2023-11-28T07:00:00.000',
  preHolidayAdvancedEndTimestamp: '2023-12-11T07:00:00.000',
  holidayEndTimestamp: '2023-12-19T07:00:00.000',
  advancedEndTimestamp: '2024-02-29T07:00:00.000',
  lastChanceEndTimestamp: '2024-02-17T07:00:00.000',
  lastChanceStartTimestamp: '2024-02-09T07:00:00.000',

  threeDayPricing: {
    earlyBird: 99,
    advanced: 109,
    onSite: 129,
  },
  playerTimeFrame: 42 * day,
}

export { second, minute, hour, day, conferenceConfig }
