const urlMaxLength = 2000

const dashboardAuthorizerConfig = {
  validTypes: ['speaker', 'sponsor', 'exhibitor', 'homeland'],
  validRegions: ['NORTH_AMERICA_EUROPE', 'ASIA_PACIFIC', 'LATIN_AMERICA', 'AFRICA', 'MENA'],
}

const dashboardStateTypes = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  REVOKED: 'REVOKED',
}

const formsConfig = {
  largeTextBox: 1000,
  mediumTextBox: 550,
  smallTextBox: 254,
}

const callForPresentationsConfig = {
  supportedLanguages: ['en', 'fr', 'de', 'it', 'es', 'pt'],
}

const proposalTransitionIds = {
  beta: '',
  integration: 'initial-0-dcd14360-194e-11ef-912b-393ea483e1e6',
  www: 'initial-74-219982c0-12f9-11ef-ae39-bda99d568b45',
}

const submitTransitionIds = {
  integration: 'initial-4-0ec81880-194f-11ef-912b-393ea483e1e6',
  beta: 'initial-69-15e34d90-535f-11ed-a613-65043eff8f69',
  www: 'initial-69-15e34d90-535f-11ed-a613-65043eff8f69',
}

const sessionStates = {
  DRAFT: 'DRAFT',
  WORKFLOW: 'WORKFLOW',
  PUBLISHED: 'PUBLISHED',
  SUBMITTED: 'SUBMITTED',
  DECLINED: 'DECLINED',
  ACCEPTED: 'ACCEPTED',
  EDITABLE: 'EDITABLE',
}

// Incoming Proposal, Rejected Proposal, Accepted Proposal, Signed Proposal, Submitted Session
const sessionWorkflowStates = {
  '0-9476c590-194e-11ef-912b-393ea483e1e6': 'SUBMITTED',
  '1-9a8ead80-194e-11ef-912b-393ea483e1e6': 'DECLINED',
  '2-9cb2c600-194e-11ef-912b-393ea483e1e6': 'ACCEPTED',
  '3-a82dc4d0-194e-11ef-912b-393ea483e1e6': 'EDITABLE',
  '4-': 'SUBMITTED',
}

export {
  urlMaxLength,
  dashboardAuthorizerConfig,
  dashboardStateTypes,
  formsConfig,
  callForPresentationsConfig,
  proposalTransitionIds,
  submitTransitionIds,
  sessionStates,
  sessionWorkflowStates,
}
