import React from 'react'
import { Row } from '@fs/zion-ui'
import { FormTextField } from '@fs/zion-form'
import { useTranslation } from 'react-i18next'
import { Link } from '@fs/zion-router'

export default function MobileNumberField() {
  const [t] = useTranslation()
  return (
    <FormTextField
      name="mobileNumber"
      label={`${t('form.mobile-number.label', 'Mobile Number')}: *`}
      helpMessage={
        <Row guttersX="nano">
          <div noShrinkX>{t('form.mobile-number.help-message', 'Message and and data rates may apply. See ')}</div>
          <Link noShrinkX to="https://www.familysearch.org/legal/text-messaging-terms-and-conditions">
            {t('form.mobile-number.help-message.link', 'Text Messaging Terms and Conditions')}
          </Link>
        </Row>
      }
    />
  )
}
