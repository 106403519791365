import React from 'react'
import { ListItem, IconButton } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { NoticeQuestion } from '@fs/zion-icon'
import { useAskUsAnythingContext } from '../..'

export default function AskUsAnythingButton({ onClick = () => {}, asListItem, size = 'sm', dense }) {
  const [t] = useTranslation()
  const { overlay } = useAskUsAnythingContext()
  const handleClick = (e) => {
    onClick(e)
    overlay.handleClick(e)
  }
  return asListItem ? (
    <ListItem
      startElement={<ListItem.Icon Icon={NoticeQuestion} />}
      data-testid="menu-aua"
      onClick={handleClick}
      primaryText={t('help.title', 'Help')}
      linkName="Help"
      dense={dense}
    />
  ) : (
    <IconButton
      size={size}
      Icon={NoticeQuestion}
      title={t('help.title', 'Help')}
      data-testid="menu-help"
      onClick={handleClick}
      linkName="Help"
    />
  )
}
