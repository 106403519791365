import React, { useMemo } from 'react'
import { ApolloProvider as ApolloClientProvider } from '@apollo/client'
import { useFeatureFlag } from '@fs/zion-flags'
import { i18n } from '@fs/zion-locale'
import { getBrightspotClient, getConfig } from '../../lib'
import { ApolloNPCProvider } from '../ApolloNPCProvider'
import { ApolloAllLanguagesClientProvider } from '../ApolloAllLanguagesClientProvider'

const ApolloProviders = ({ children }) => {
  const persistQueries = useFeatureFlag('rootstech_persist_queries')
  const envConfig = getConfig()
  const userLocale = i18n.language
  // Note: because feature flags could make this component re-render,
  // putting this call in a memo partially fixes a duplicate canceled call.
  // It will still cancel the first call if persistQueries.isOn changes
  const client = useMemo(() => getBrightspotClient(persistQueries.isOn, envConfig, userLocale), [
    envConfig,
    persistQueries.isOn,
    userLocale,
  ])

  return (
    <ApolloClientProvider client={client.caeClient}>
      <ApolloNPCProvider client={client.npcClient}>
        <ApolloAllLanguagesClientProvider client={client.allLanguagesClient}>
          {children}
        </ApolloAllLanguagesClientProvider>
      </ApolloNPCProvider>
    </ApolloClientProvider>
  )
}

export default ApolloProviders
