import React from 'react'
import { FlowGrid, useContainerWidth, Row, Image, H2, Separator } from '@fs/zion-ui'
import ErrorBoundary from '@fs/zion-error-boundary'
import { PillButton, SafeRichText, VideoProvider, getCustomImage } from '..'

export default function MediaPromo({ promo, children, noAction, videoAtEnd, isGif }) {
  const atWidth = useContainerWidth()
  const { title, description, video, ctaText, url } = promo

  const videoComponent = (
    <div>
      <ErrorBoundary>
        {!isGif && <VideoProvider videoProvider={video?.videoProvider} thumbnail={getCustomImage(promo)} />}
        {isGif && (
          <div style={{ aspectRatio: '16 / 9' }}>
            <Image src={getCustomImage(promo)} alt="" />
          </div>
        )}
      </ErrorBoundary>
    </div>
  )
  const copyText = (
    <div>
      <H2 supplementary centered={atWidth({ default: true, lg: false })}>
        {title}
      </H2>
      <Separator />
      <SafeRichText size="sm" dirtyInput={description} />
      {!noAction && ctaText && (
        <>
          <Separator size="sm" />
          {children || (
            <Row alignX={atWidth({ default: 'center', lg: 'start' })}>
              <PillButton to={url} linkName={ctaText}>
                {ctaText}
              </PillButton>
            </Row>
          )}
        </>
      )}
    </div>
  )

  return (
    <FlowGrid columnCount={atWidth({ default: 1, lg: 2 })}>
      {!videoAtEnd && videoComponent}
      {!videoAtEnd && copyText}
      {videoAtEnd && copyText}
      {videoAtEnd && videoComponent}
    </FlowGrid>
  )
}
