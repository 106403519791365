import React, { useEffect, useState } from 'react'
import { SocialLike, NoticeLoading } from '@fs/zion-icon'
import { IconButton, Row, WhiteTheme, colors } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { i18n } from '@fs/zion-locale'
import { css } from '@emotion/core'
import { useLazyRequest } from '../hooks'
import { deleteUserSessionRating, getSessionRatings, getUserSessionRating, updateUserSessionRatings } from '../api'

const vrCss = css`
  border-inline-start: 1px solid black;
  content: none;
  height: 80%;
  padding-inline: 16px;
`
const textCss = css`
  font-size: 14px;
  font-weight: bold;

  [lang|='zh'] &,
  [lang|='ja'] &,
  [lang|='ko'] & {
    // Specify top and bottom separately to get low-emphasis horizontal padding without re-specifying CJK selector there
    font-size: 16px;
  }
`

export default function RatingButtons({ contentId: sessionId, cisId, size = 'sm' }) {
  const { t } = useTranslation()
  const [likes, setLikes] = useState(0)
  const [userLikes, setUserLikes] = useState(false)
  const [userDislikes, setUserDislikes] = useState(false)
  const userLocale = i18n.language

  const [getRatings, { loading: loadingRatings, data: ratings }] = useLazyRequest(getSessionRatings)
  const [getUserRating, { loading: loadingUserRating, data: userRating }] = useLazyRequest(getUserSessionRating)
  const [removeRating] = useLazyRequest(deleteUserSessionRating.bind(this, { sessionId, cisId }))
  const [dislikeSession] = useLazyRequest(updateUserSessionRatings.bind(this, { sessionId, cisId }, -1))
  const [likeSession] = useLazyRequest(updateUserSessionRatings.bind(this, { sessionId, cisId }, 1))

  useEffect(() => {
    getRatings({ sessionId })
    getUserRating({ sessionId, cisId })
  }, [cisId, getRatings, getUserRating, sessionId])

  useEffect(() => {
    if (ratings) {
      setLikes(ratings.score)
    }
    if (userRating) {
      if (userRating.score === 1) {
        setUserLikes(true)
        setUserDislikes(false)
      } else if (userRating.score === -1) {
        setUserLikes(false)
        setUserDislikes(true)
      }
    }
  }, [loadingRatings, loadingUserRating, ratings, userRating])

  const onLikeClick = () => {
    if (userLikes) {
      // unlike
      setUserLikes(false)
      setLikes(likes - 1)
      removeRating()
    } else {
      // like
      setUserLikes(true)
      setUserDislikes(false)
      setLikes(likes + 1)
      likeSession()
    }
  }

  const onDislikeClick = () => {
    if (userDislikes) {
      // Un-dislike
      setUserDislikes(false)
      removeRating()
    } else {
      // dislike
      setUserLikes(false)
      if (userLikes) setLikes(likes - 1)
      setUserDislikes(true)
      dislikeSession()
    }
  }

  const Icon = loadingRatings && loadingUserRating ? NoticeLoading : SocialLike

  return sessionId && cisId ? (
    <div style={{ borderRadius: 20, background: colors.gray05, paddingLeft: 8, paddingRight: 8 }}>
      <WhiteTheme>
        <Row guttersY="none" guttersX="xs" alignY="middle">
          <div style={{ marginInlineStart: likes > 0 ? 4 : 16 }}>
            <Row guttersX="xs" alignY="middle">
              {likes > 0 && (
                <div css={textCss}>{new Intl.NumberFormat(userLocale, { notation: 'compact' }).format(likes)}</div>
              )}
              <IconButton
                size={size}
                Icon={Icon}
                iconToggle={userLikes}
                onClick={onLikeClick}
                title={t('rating.like.action', 'Like')}
                linkName="Like"
              />
            </Row>
          </div>
          <div css={vrCss}>
            <IconButton
              size={size}
              Icon={Icon}
              iconToggle={userDislikes}
              iconDirection="backward"
              onClick={onDislikeClick}
              title={t('rating.dislike.action', 'Dislike')}
              linkName="Dislike"
            />
          </div>
        </Row>
      </WhiteTheme>
    </div>
  ) : null
}
