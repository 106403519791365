import React from 'react'
import { useUser } from '@fs/zion-user'
import { ChatOverlayButton, useChatOverlay } from '@fs/zion-chat'
import { css } from '@emotion/core'
import { SocialChat } from '@fs/zion-icon'
import { useTranslation } from 'react-i18next'
import { boolAttr } from '@fs/zion-frontend-friends'
import { useRouteMatch } from '@fs/zion-router'
import PropTypes from 'prop-types'
import { IconButton } from '@fs/zion-ui'
import { useFeatureFlag } from '@fs/zion-flags'
import ChatLoginButton from './ChatLoginButton'
import { useIsMdWidth } from '../../hooks'
import { floatingChatConfig } from '../../config'
import { PillButton } from '../../lib'

const floatingCss = css`
  position: fixed;
  inset-inline-end: 30px;
  bottom: 10%;
  z-index: 1;
`
const overlayButtonHackCss = css`
  & > * {
    height: auto;
  }
  & [class*='dotCss_'] {
    top: 8px;
  }
`

const ChatButton = ({ ariaLabel, children, asComponent, chatRoute, ...props }) => {
  const user = useUser()
  const [t] = useTranslation()
  if (!user.signedIn && !user.userLoading)
    return (
      <ChatLoginButton asComponent={asComponent} ariaLabel={ariaLabel} chatRoute={chatRoute ?? 'default'} {...props}>
        {children}
      </ChatLoginButton>
    )

  const Tag = asComponent || ChatOverlayButton

  return (
    <div css={overlayButtonHackCss}>
      <Tag {...props} aria-label={t('logged-out.reason2', 'Chat with industry experts and other attendees')}>
        {children}
      </Tag>
    </div>
  )
}
ChatButton.propTypes = {
  ariaLabel: PropTypes.string,
  asComponent: PropTypes.elementType,
  chatRoute: PropTypes.string,
}

export const FloatingChatButton = () => {
  const [t] = useTranslation()
  const chatOverlay = useChatOverlay()
  const chatFlag = useFeatureFlag('rootstech_chat')
  const isMdWidth = useIsMdWidth()
  const blacklistMatch = useRouteMatch(floatingChatConfig.ROUTE_BLACKLIST)

  if (blacklistMatch) return null

  return (
    chatFlag.isOn && (
      <div css={floatingCss} is-md-width={boolAttr(isMdWidth)}>
        <ChatButton
          asComponent={isMdWidth ? IconButton : PillButton}
          color="blue"
          onClick={chatOverlay.handleClick}
          Icon={SocialChat}
          emphasis="high"
          size={isMdWidth ? 'md' : 'lg'}
          useBillboardColor
        >
          {t('chat.button', 'Chat')}
        </ChatButton>
      </div>
    )
  )
}

export default ChatButton
