import React, { Children } from 'react'
import { Ghost, LayoutBand } from '@fs/zion-ui'
import { RainbowDivider, ResponsiveSectionBand } from '../../../lib'
import WhatIsRootsTechBanner from './WhatIsRootsTechBanner'
import LibraryHeading from './LibraryHeading'
import { circlesAppleJpg, circlesFireJpg, circlesBlueberryJpg, circlesSeafoamJpg } from '../../../images'
import RainbowCarouselSection from './RainbowCarouselSection'
import SponsorsCard from './SponsorsCard'

export default function OnDemandLibrarySections({ page, noHeading }) {
  const sectionGraphics = [
    { flavor: 'blueberry', bg: circlesBlueberryJpg },
    { flavor: 'fire', bg: circlesFireJpg },
    { flavor: 'apple', bg: circlesAppleJpg },
    { flavor: 'seafoam', bg: circlesSeafoamJpg },
  ]

  // eslint-disable-next-line no-underscore-dangle
  const content = page?.content?.filter((item) => item.__typename !== 'VideoModule')

  return page ? (
    <>
      {!noHeading && (
        <>
          <RainbowDivider thick and full />
          <LayoutBand>
            <LibraryHeading />
          </LayoutBand>
        </>
      )}
      {Children.toArray(
        content?.map((section, ind) => {
          const sectionGraphic = sectionGraphics?.[ind % sectionGraphics.length]
          return (
            <Ghost heightEstimate={600} keepVisible>
              <ResponsiveSectionBand>
                <RainbowCarouselSection
                  overline={section.overline || ''}
                  title=""
                  {...section}
                  image={sectionGraphic?.bg}
                  flavor={sectionGraphic?.flavor}
                  position={ind}
                />
              </ResponsiveSectionBand>
            </Ghost>
          )
        })
      )}
      <ResponsiveSectionBand>
        <Ghost keepVisible heightEstimate={200}>
          <WhatIsRootsTechBanner />
        </Ghost>
      </ResponsiveSectionBand>
      <ResponsiveSectionBand>
        <Ghost keepVisible heightEstimate={680}>
          <SponsorsCard />
        </Ghost>
      </ResponsiveSectionBand>
    </>
  ) : null
}
