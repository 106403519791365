import { useState, useEffect, useCallback, useRef } from 'react'
import { useTimeTravel } from '../../providers'

export default function useCountdownTimer(targetTime, interval) {
  const [duration, setDuration] = useState(null)
  const timerRef = useRef()
  const { getNow } = useTimeTravel()

  const updateDuration = useCallback(() => {
    const remainingTime = targetTime - getNow()
    if (remainingTime <= 0) {
      setDuration(0)
      return
    }

    setDuration(remainingTime)
    timerRef.current = setTimeout(updateDuration, interval)
  }, [interval, targetTime, getNow])

  useEffect(() => {
    updateDuration()
    const timerId = timerRef.current

    return () => {
      clearTimeout(timerId)
    }
  }, [interval, targetTime, updateDuration])

  return duration
}
