import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { trackLink } from '@fs/zion-analytics'
import { ContentAdd } from '@fs/zion-icon'
import { useUser } from '@fs/zion-user'
import { useOverlay } from '@fs/zion-ui'
import { useFeatureFlag } from '@fs/zion-flags'
import { ContentCheck } from '@fs/zion-icon/dist/cjs/icons'
import { useLoggedOutOverlay } from '../../providers/LoggedOutOverlayProvider'
import ChewableButton from './ChewableButton'
import PlaylistManagementOverlay from '../../components/playlists/components/PlaylistManagementOverlay'
import PlaylistsOverlay from '../PlaylistsOverlay'
import CreatePlaylistOverlay from '../../components/playlists/components/CreatePlaylistOverlay'
import { usePlaylistsContext } from '../../providers/PlaylistsProvider'
import { usePlaylistsContextV2 } from '../../providers/PlaylistsProviderV2'

export default function PlaylistsButton({ sessionId, asIconButton, size, ...props }) {
  const [t] = useTranslation()
  const improvedUxEnabled = useFeatureFlag('rootstech_playlists_improved_ux').isOn
  const { getPlaylistsForItem } = usePlaylistsContext()
  const { findPlaylistsWithItem } = usePlaylistsContextV2()
  const user = useUser()
  const managementOverlay = useOverlay()
  const playlistsOverlay = useOverlay()
  const createPlaylistOverlay = useOverlay()
  const { overlay: loggedOutOverlay } = useLoggedOutOverlay()
  const [isInSomePlaylist, setIsInSomePlaylist] = useState(false)

  const onClick = (e) => {
    const loggedIn = user?.signedIn ?? false
    if (!loggedIn) {
      trackLink({ name: 'Playlists: logged out', action: 'click_action' })
      loggedOutOverlay?.handleClick?.(e)
    } else {
      trackLink({ name: 'Playlists: logged in', action: 'click_action' })
      const overlay = improvedUxEnabled ? managementOverlay : playlistsOverlay
      overlay.handleClick(e)
    }
  }

  const label = t('playlists.label', 'Playlists')

  useEffect(() => {
    if (user.signedIn) {
      const doPlaylistCheck = async () => {
        const foundPlaylists = await (improvedUxEnabled
          ? findPlaylistsWithItem({ itemId: sessionId })
          : getPlaylistsForItem({ sessionId, signedIn: user.signedIn }))
        setIsInSomePlaylist(foundPlaylists.length > 0)
      }
      doPlaylistCheck()
    } else {
      setIsInSomePlaylist(false)
    }
  }, [findPlaylistsWithItem, getPlaylistsForItem, improvedUxEnabled, sessionId, user.signedIn])

  // todo: figure out if it's possible to show just the icon in color
  const useColoredButton = false // improvedUxEnabled && isInSomePlaylist

  return (
    <>
      <ChewableButton
        asIconButton={asIconButton}
        size={size}
        onClick={onClick}
        Icon={improvedUxEnabled && isInSomePlaylist ? ContentCheck : ContentAdd}
        data-testid="playlists-dialog"
        emphasis="low"
        color={useColoredButton ? 'blue' : null}
        useBillboardColor={useColoredButton}
        {...props}
      >
        {label}
      </ChewableButton>
      {improvedUxEnabled && (managementOverlay.isOpen || managementOverlay.transitioning) && (
        <PlaylistManagementOverlay target={managementOverlay} sessionId={sessionId} />
      )}
      {!improvedUxEnabled && (
        <>
          {(playlistsOverlay.isOpen || playlistsOverlay.transitioning) && (
            <PlaylistsOverlay
              {...playlistsOverlay}
              createPlaylistOverlay={createPlaylistOverlay}
              sessionId={sessionId}
            />
          )}
          {(createPlaylistOverlay.isOpen || createPlaylistOverlay.transitioning) && (
            <CreatePlaylistOverlay {...createPlaylistOverlay} sessionId={sessionId} />
          )}
        </>
      )}
    </>
  )
}
