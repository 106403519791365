import React, { useEffect } from 'react'
import { css } from '@emotion/core'
import { useContainerWidth, colors, Row } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { boolAttr } from '@fs/zion-frontend-friends'
import VisuallyHidden from '@reach/visually-hidden'
import { convertMsToTime } from '../helpers'
import { second, minute, day } from '../../config'
import useCountdownTimer from './useCountdownTimer'
import { useTimeTravel } from '../../providers'

const digitContainerCss = css`
  border-radius: 8px;
  background-color: var(--gray02);
  width: fit-content;
  padding: 10px;
  color: ${colors.gray90};
  font-weight: 400;
  font-size: 48px;

  &[is-md-width] {
    font-size: 40px;
    padding: 5px 7px;
  }

  &[color='blue'] {
    background-color: ${colors.blue02};
  }
`
const digitSetCss = css`
  display: flex;
  & > div {
    margin-inline-end: 2px;
    margin-inline-start: 2px;
    line-height: 45px;
  }
`
const digitColumnCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  & + & {
    margin-inline-start: 24px;
  }
`

const digitLabelCss = css`
  margin-top: 10px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
`

const getDigits = (num) => {
  if (!num || num < 0 || num > 999) {
    return [0, 0, 0]
  }

  const digitStr = num.toString().padStart(3, '0')
  const digits = digitStr.split('').map(Number)
  return digits
}

const CountdownDigitGroup = ({ num, color }) => {
  const atWidth = useContainerWidth()
  const isMdWidth = atWidth({ default: true, sm: false })
  const [digit1, digit2, digit3] = getDigits(num)

  const Digit = ({ int }) => (
    <div css={digitContainerCss} color={color} is-md-width={boolAttr(isMdWidth)}>
      {int}
    </div>
  )

  return (
    <div css={digitSetCss}>
      {digit1 !== 0 && <Digit int={digit1} />}
      <Digit int={digit2} />
      <Digit int={digit3} />
    </div>
  )
}

export default function CountDown({
  startTimestamp,
  timesToShow = { days: true, hours: true, minutes: true, seconds: false },
  color,
  noReload,
}) {
  const { getNow } = useTimeTravel()
  const hasDays = startTimestamp - getNow() > day
  const [t] = useTranslation()

  const timeToWait = !hasDays ? second : minute
  const timeRemaining = useCountdownTimer(startTimestamp, timeToWait)

  useEffect(() => {
    if (timeRemaining === 0) {
      !noReload && window.location.reload()
    }
  }, [noReload, timeRemaining])

  const { days, hours, minutes, seconds } = convertMsToTime(timeRemaining)
  return (
    <>
      {/* there was not a reasonable way to make the countdown accessible. So we have a separate feature for screen readers. */}
      <VisuallyHidden>
        {!hasDays ? null : (
          <>
            {days} {t('homepage.countdown.days', 'Days')}{' '}
          </>
        )}
        {!timesToShow.hours ? null : (
          <>
            {hours} {t('homepage.countdown.hours', 'Hours')}{' '}
          </>
        )}
        {!timesToShow.minutes ? null : (
          <>
            {minutes} {t('homepage.countdown.minutes', 'Minutes')}{' '}
          </>
        )}
        {hasDays ? null : (
          <>
            {seconds} {t('homepage.countdown.secondsRemaining', 'Seconds')}{' '}
          </>
        )}
      </VisuallyHidden>

      <Row aria-hidden>
        {!hasDays ? null : (
          <div css={digitColumnCss}>
            <CountdownDigitGroup color={color} num={days} />
            <div css={digitLabelCss}>{t('homepage.countdown.days', 'Days')}</div>
          </div>
        )}
        {!timesToShow.hours ? null : (
          <div css={digitColumnCss}>
            <CountdownDigitGroup color={color} num={hours} />
            <div css={digitLabelCss}>{t('homepage.countdown.hours', 'Hours')}</div>
          </div>
        )}
        {!timesToShow.minutes ? null : (
          <div css={digitColumnCss}>
            <CountdownDigitGroup color={color} num={minutes} />
            <div css={digitLabelCss}>{t('homepage.countdown.minutes', 'Minutes')}</div>
          </div>
        )}
        {hasDays ? null : (
          <div css={digitColumnCss}>
            <CountdownDigitGroup color={color} num={seconds} />
            <div css={digitLabelCss}>{t('homepage.countdown.secondsRemaining', 'Seconds')}</div>
          </div>
        )}
      </Row>
    </>
  )
}
