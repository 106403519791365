import React from 'react'
import { css } from '@emotion/core'
import { boolAttr } from '@fs/zion-frontend-friends'

const sponsorPrimaryImageCss = css`
  border-radius: 50%;
  object-fit: contain;
  max-width: 160px;
  max-height: 160px;
  margin-inline-end: 3vw;
  padding: 4px;
  background-color: #ffffff;
  width: 160;
  height: 160;
  &[is-md-width] {
    width: 80;
    height: 80;
  }
  aspect-ratio: 1/1;
`

export default function SponsorImage({ isMdWidth, image }) {
  // NOTE: Zion-UI's Image doesn't support custom CSS so we use the native img here.
  return (
    <img
      src={image}
      alt=""
      data-testid="company-logo"
      aria-hidden
      css={sponsorPrimaryImageCss}
      is-md-width={boolAttr(isMdWidth)}
      loading="lazy"
    />
  )
}
