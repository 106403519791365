import {
  FlowGrid,
  Row,
  Separator,
  useContainerWidth,
  Card,
  colors,
  Paragraph,
  H5,
  Overline,
  Subheading,
  H2,
} from '@fs/zion-ui'
import { useTranslation, Trans } from 'react-i18next'
import React from 'react'
import { css } from '@emotion/core'
import { PillButton, BlackText, parseMsToISO, dateTimeFormat } from '..'
import { circlesFireJpg, circlesSeafoamJpg } from '../../images'
import { conferenceConfig, cventConfig, day } from '../../config'
import { useTimeTravel } from '../../providers'

const {
  earlyBirdEndTimestamp,
  advancedEndTimestamp,
  threeDayPricing,
  blackFridayEndTimestamp,
  preHolidayAdvancedEndTimestamp,
  holidayEndTimestamp,
  lastChanceEndTimestamp,
  lastChanceStartTimestamp,
} = conferenceConfig

const strikethroughCss = css`
  text-decoration: line-through ${colors.red40};
  // thanks safari
  text-decoration-thickness: from-font;
  color: ${colors.gray100};
`

const getHeading = (t, phase) => {
  switch (phase) {
    case 'earlyBird':
      return t('in-person.early-bird-pricing', 'EARLY BIRD PRICING')
    case 'blackFriday':
      return t('in-person.black-friday-pricing', 'BLACK FRIDAY PRICING')
    case 'advanced':
      return t('in-person.advanced-pricing', 'ADVANCED PRICING')
    case 'holiday':
      return t('in-person.holiday-pricing', 'HOLIDAY SALE PRICING')
    case 'lastChance':
      return t('in-person.last-chance-pricing', 'LAST CHANCE SALE PRICING')
    default:
      return t('in-person.onsite-pricing', 'ON-SITE PRICING')
  }
}

const getPricing = (phase) => {
  const { earlyBird, advanced, onSite } = threeDayPricing

  switch (phase) {
    case 'earlyBird':
    case 'blackFriday':
    case 'holiday':
      return `${earlyBird}`
    case 'advanced':
      return `${advanced}`
    case 'lastChance':
      return `${earlyBird}`
    default:
      return onSite
  }
}

const getDateOverline = (t, phase) => {
  const optionsWithoutYear = {
    month: 'long',
    day: 'numeric',
  }
  const optionsWithYear = {
    ...optionsWithoutYear,
    year: 'numeric',
  }
  switch (phase) {
    case 'earlyBird':
      return `${dateTimeFormat('2023-09-18T07:00:00.000', optionsWithoutYear)}–${dateTimeFormat(
        new Date(earlyBirdEndTimestamp) - day,
        optionsWithYear
      )}`
    case 'blackFriday':
      return `${dateTimeFormat(earlyBirdEndTimestamp, optionsWithoutYear)}–${dateTimeFormat(
        new Date(blackFridayEndTimestamp) - day,
        optionsWithYear
      )}`
    case 'holiday':
    case 'advanced':
      return `${dateTimeFormat(earlyBirdEndTimestamp, optionsWithYear)}–${dateTimeFormat(
        new Date(advancedEndTimestamp) - day,
        optionsWithYear
      )}`
    case 'lastChance':
      return `${dateTimeFormat(lastChanceStartTimestamp, optionsWithYear)}–${dateTimeFormat(
        new Date(lastChanceEndTimestamp) - day,
        optionsWithYear
      )}`
    default:
      return `${dateTimeFormat(advancedEndTimestamp, optionsWithoutYear)}–${dateTimeFormat(
        '2024-03-02T07:00:00.000',
        optionsWithYear
      )}`
  }
}

const getFootnote = (t, phase) => {
  switch (phase) {
    case 'earlyBird':
      return t(
        'in-person.early-bird-pricing.footnote',
        '*Standard Pricing of $129 for a 3-day pass and $79 for a 1-day pass starts 1 November 2024'
      )
    case 'blackFriday':
      return t(
        'in-person.black-friday-pricing.footnote',
        '*$109 US Advanced Pricing resumes 28 November 2023. $129 On-site Pricing starts 6 March 2025. The price for the 1-Day Pass does not change.'
      )
    case 'holiday':
    case 'advanced':
      return t(
        'in-person.advanced-pricing.footnote',
        '*$129 On-site Pricing starts 6 March 2025. The price for the 1-Day Pass does not change.'
      )
    case 'lastChance':
      return t(
        'in-person.last-chance-pricing.footnote',
        '*$109 US Advanced Pricing resumes 17 February 2024. $129 On-site Pricing starts 6 March 2025. The price for the 1-Day Pass does not change.'
      )
    default:
      return ''
  }
}

const getDiscountText = (phase) => {
  if (phase === 'blackFriday') {
    return (
      <Trans
        i18nKey="in-person.black-friday-pricing.heading"
        defaults="Black Friday Sale! Save $10 on the 3-Day Pass.<br />Sale ends 27 November."
      />
    )
  }
  if (phase === 'holiday') {
    return (
      <Trans
        i18nKey="in-person.holiday-pricing.heading"
        defaults="Holiday Sale! Save $10 on the 3-Day Pass.<br />Sale ends 18 December."
      />
    )
  }
  if (phase === 'lastChance') {
    return (
      <Trans
        i18nKey="in-person.last-chance-pricing.heading"
        defaults="Last Chance Sale! Save $10 on the 3-Day Pass.<br />Sale ends 16 February."
      />
    )
  }
  return null
}

const getPricingPhase = (now) => {
  if (now <= earlyBirdEndTimestamp) return 'earlyBird'
  if (now <= blackFridayEndTimestamp) return 'blackFriday'
  if (now <= preHolidayAdvancedEndTimestamp) return 'advanced'
  if (now <= holidayEndTimestamp) return 'holiday'
  if (now <= lastChanceEndTimestamp) return 'lastChance'
  if (now <= advancedEndTimestamp) return 'advanced'
  return 'onSite'
}

const DiscountBanner = () => {
  const { getNow } = useTimeTravel()
  const pricingPhase = getPricingPhase(parseMsToISO(getNow()))
  const heading = getDiscountText(pricingPhase)

  if (!heading) {
    return null
  }

  return (
    <>
      <Card backgroundImage={circlesFireJpg}>
        <H5 centered>{heading}</H5>
      </Card>
      <Separator />
    </>
  )
}

const PricingCard = ({ title, overline, heading, subHeading, to, linkName, src }) => {
  const [t] = useTranslation()
  return (
    <Card backgroundImage={src}>
      <BlackText>
        <H5 flavor="charcoal" centered>
          {title}
        </H5>
      </BlackText>
      <Separator />
      <div style={{ background: colors.gray00 }}>
        <Separator />
        <H2 supplementary centered>
          <Overline>{overline}</Overline>
          {heading}
          <Subheading>{subHeading}</Subheading>
        </H2>
        <Separator size="sm" />
        <Row alignX="center">
          <PillButton to={to} linkName={linkName} target="_blank">
            {t('site-banner.register-now', 'Register Now')}
          </PillButton>
        </Row>
        <Separator />
      </div>
    </Card>
  )
}

export default function PricingCards() {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()
  const { getNow } = useTimeTravel()
  const nowString = parseMsToISO(getNow())

  const pricingPhase = getPricingPhase(nowString)

  return (
    <>
      <H2 supplementary centered>
        {t('in-person.pricing.2024', '2025 In-Person Pricing')}
      </H2>
      <Separator />
      <DiscountBanner />
      <FlowGrid columnCount={atWidth({ default: 1, md: 2 })}>
        <PricingCard
          src={circlesFireJpg}
          title={getHeading(t, pricingPhase)}
          overline={getDateOverline(t, pricingPhase)}
          heading={
            <>
              {pricingPhase === 'holiday' ? <span css={strikethroughCss}>${getPricing('advanced')}</span> : ''}{' '}
              {`$${getPricing(pricingPhase)}${pricingPhase !== 'onSite' ? '*' : ''}`}{' '}
              <span style={{ color: colors.gray50 }}>US</span>
            </>
          }
          subHeading={t('in-person.3-day-pass', '3-Day Pass')}
          to={cventConfig.registrationUrl}
          linkName="Registration: 3-day-pass"
        />
        <PricingCard
          src={circlesSeafoamJpg}
          title={t('in-person.one-day-pricing', 'ONE DAY PRICING')}
          // empty space to help with layout
          overline="&nbsp;"
          heading={
            <>
              <span>$69</span> <span style={{ color: colors.gray50 }}>US</span>
            </>
          }
          subHeading={t('in-person.1-day-pass', '1-Day Pass')}
          to={cventConfig.registrationUrl}
          linkName="Registration: 1-day-pass"
        />
      </FlowGrid>
      <Separator size="xxs" />
      <Row alignX="center">
        <Paragraph as="strong" size="sm" centered>
          {getFootnote(t, pricingPhase)}
        </Paragraph>
      </Row>
    </>
  )
}
