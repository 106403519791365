import { i18n } from '@fs/zion-locale'
import { languageConfig, searchConfig } from '../../config'

// IETF language tags combine subtags from other standards such as ISO 639, ISO 15924, ISO 3166-1 and UN M.49.
// We are currently only concerned with the country code ISO 639-1 and 639-2

const languageDisplayNamesOf = (translateTo, code) => {
  if (code === 'pon') {
    return i18n.t('global.pohnpeian', 'Pohnpeian')
  }
  if (code === 'ase') {
    return i18n.t('global.asl', 'American Sign Language')
  }
  if (code === 'tl') {
    return i18n.t('global.tagalog', 'Tagalog')
  }
  if (typeof Intl === 'undefined' || typeof Intl.DisplayNames === 'undefined') {
    return code
  }

  return new Intl.DisplayNames([translateTo], { type: 'language' }).of(code)
}

/**
 * @param {String} code the language code to get the name of
 * @returns the language name in the users language
 */
const getLanguageName = (code) => {
  const userCode = i18n.language
  return languageDisplayNamesOf(userCode, code)
}

/**
 * @param {String} code the language code to get the name of
 * @returns the language name in the provided language
 */
const getNativeLanguageName = (code) => {
  try {
    const languageName = languageDisplayNamesOf(code, code)
    return languageName.charAt(0).toLocaleUpperCase(code) + languageName.slice(1)
  } catch {
    return ''
  }
}

/**
 * @param {String} code ISO 639 code
 * @returns The language code that BS can read; RFC 5646 {language}-{country/region}
 */
const getSearchLanguageCode = (code) => {
  return searchConfig.languageWhiteList.find((value) => value.includes(code))
}

const exceptions = ['zh-HK']
/**
 * @param {String} code language code
 * @returns ISO 639-2 code (if we have it mapped)
 */
const getISO639_2LanguageCode = (code = '') => {
  let langCode = code

  // it's a BSP code that we can convert to a two letter code
  if (!exceptions.includes(code) && code.length > 2) {
    langCode = code.slice(0, 2)
  }

  const fallback = languageConfig.iso639Conversions.en
  return languageConfig.iso639Conversions[langCode] ?? fallback
}

const isUsersLocale = (BSLocale) => {
  if (BSLocale === 'zh-CN') {
    return i18n.language === 'zh-hans'
  }
  if (i18n.language === BSLocale.split('-').shift()) {
    return true
  }
  return false
}

const getBrowserSeoLocales = (alternateLocales = []) => {
  return alternateLocales
    .map((locale) => {
      if (languageConfig.chineseLocales.includes(locale)) {
        return languageConfig.chineseLangMapping[locale]
      }
      return locale.split('-')[0]
    })
    .filter((locale) => locale !== i18n.language)
}

const getProspectiveLanguages = () => {
  return languageConfig.appAdditionalLanguages.filter((_, i) => i !== 0)
}

/**
 * gets the browsers language even if we do not support it in our app
 * @returns the language in the format that frontier uses
 */
const getBrowserLanguage = () => {
  if (navigator.language === 'zh-CN') {
    return 'zh-hans'
  }

  return navigator.language.slice(0, 2)
}

export {
  getLanguageName,
  getNativeLanguageName,
  getSearchLanguageCode,
  getISO639_2LanguageCode,
  getBrowserSeoLocales,
  isUsersLocale,
  getProspectiveLanguages,
  getBrowserLanguage,
}
