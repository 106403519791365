import { useQuery } from '@apollo/client'
import { getOneOffPage } from '../api'
import useErrorHandlerOverlay from './useErrorHandlerOverlay'
import { brightspotConfig } from '../config'
import { useVideoWatchProgress } from './useWatchHistory'

const { imageSizes } = brightspotConfig
const {
  leadThumbnailImage,
  leadPromoImage,
  leadProfileImage,
  leadImage,
  profileImage,
  promoImage,
  thumbnailImage,
} = imageSizes

export default function useOneOffPageData({ path }) {
  const variables = {
    path,
    ...leadPromoImage,
    ...leadProfileImage,
    ...leadThumbnailImage,
    ...leadImage,
    ...profileImage,
    ...promoImage,
    ...thumbnailImage,
  }
  const { loading, data, error } = useQuery(getOneOffPage(), { variables })
  useErrorHandlerOverlay(error)
  const { data: dataWithWatchHistory, loading: watchHistoryLoading } = useVideoWatchProgress({
    loading,
    data: data?.OneOffPageDetail,
  })

  return { loading: watchHistoryLoading, data: dataWithWatchHistory, error }
}
