import React, { createContext, useEffect, useContext, useCallback, useReducer } from 'react'
import { useUser } from '@fs/zion-user'
import usePlaylistsApi from '../hooks/usePlaylistsApi'
import { deepCopy } from '../lib/helpers'

const ACTION = {
  PLAYLISTS_LOADED: 'PLAYLISTS_LOADED',
  CREATE_PLAYLIST: 'CREATE_PLAYLIST',
  DELETE_PLAYLIST: 'DELETE_PLAYLIST',
  GET_PLAYLIST_ITEMS: 'GET_PLAYLIST_ITEMS',
  EDIT_PLAYLIST: 'EDIT_PLAYLIST',
  ADD_TO_PLAYLIST: 'ADD_TO_PLAYLIST',
  BULK_ADD_TO_PLAYLIST: 'BULK_ADD_TO_PLAYLIST',
  REMOVE_FROM_PLAYLIST: 'REMOVE_FROM_PLAYLIST',
  SELECT_PLAYLIST: 'SELECT_PLAYLIST',
  REORDER_PLAYLIST: 'REORDER_PLAYLIST',
  FOLLOW_PLAYLIST: 'FOLLOW_PLAYLIST',
}

const initialState = {
  playlists: [],
  series: [],
  selectedPlaylist: {},
  defaultPlaylist: {},
}

const alphabeticalOrder = (a, b) => {
  if (a?.default) {
    return -1
  }
  if (b?.default) {
    return 1
  }

  const aName = a?.name?.toLocaleLowerCase() ?? ''
  const bName = b?.name?.toLocaleLowerCase() ?? ''
  return aName.localeCompare(bName)
}

function reducer(state = initialState, { type, value }) {
  switch (type) {
    case ACTION.PLAYLISTS_LOADED: {
      const { results } = value
      const series = results.filter((p) => Boolean(p.parent)).sort(alphabeticalOrder)
      const defaultPlaylist = results.find((p) => p.default)
      const playlists = results.filter((p) => !p.parent).sort(alphabeticalOrder)
      return {
        ...state,
        playlists: [...playlists],
        series: [...series],
        defaultPlaylist: { ...defaultPlaylist },
      }
    }
    case ACTION.GET_PLAYLIST_ITEMS: {
      const { items, isSeries } = value
      const propSelector = isSeries ? 'series' : 'playlists'

      if (items) {
        return {
          ...state,
          selectedPlaylist: { ...state.selectedPlaylist, items },
          defaultPlaylist:
            state.selectedPlaylist.id === state.defaultPlaylist.id
              ? { ...state.defaultPlaylist, items }
              : state.defaultPlaylist,
          [propSelector]: [
            ...state[propSelector].map((p) => {
              if (p.id === value.playlistId) {
                p.items = [...items]
              }
              return p
            }),
          ],
        }
      }
      return { ...state }
    }
    case ACTION.DELETE_PLAYLIST: {
      if (value.isFollower) {
        const filteredPlaylists = state.series.filter(({ id }) => id !== value.playlistId)
        return {
          ...state,
          selectedPlaylist: state.defaultPlaylist,
          series: [...filteredPlaylists],
        }
      }

      const filteredPlaylists = state.playlists.filter(({ id }) => id !== value.playlistId)
      return {
        ...state,
        selectedPlaylist: state.defaultPlaylist,
        playlists: [...filteredPlaylists],
      }
    }
    case ACTION.CREATE_PLAYLIST: {
      const { playlist } = value
      return {
        ...state,
        playlists: [...state.playlists, playlist].sort(alphabeticalOrder),
      }
    }
    case ACTION.EDIT_PLAYLIST: {
      const playlist = state.playlists.find((p) => p.id === value.playlistId)
      const updatedPlaylist = { ...playlist, name: value.name, description: value.description }

      return {
        ...state,
        selectedPlaylist: state.selectedPlaylist.id === value.playlistId ? updatedPlaylist : state.selectedPlaylist,
        playlists: [
          ...state.playlists.map((p) => {
            if (p.id === value.playlistId) {
              return updatedPlaylist
            }
            return p
          }),
        ],
      }
    }
    case ACTION.ADD_TO_PLAYLIST: {
      const { playlistId, sessionId, addedAt } = value
      const playlist = state.playlists.find((p) => p.id === playlistId)
      const addedSession = { id: sessionId, addedAt }

      const updatedPlaylist = {
        ...playlist,
        size: playlist.size + 1,
      }

      if (playlist.items) {
        const existingItems = playlist?.items ?? []
        if (playlist.ordering.method === 'custom') {
          updatedPlaylist.ordering = { ...playlist.ordering, ids: [...playlist.ordering.ids, sessionId] }
          updatedPlaylist.items = [...existingItems, addedSession]
        } else if (playlist.ordering.ascending === true) {
          updatedPlaylist.items = [...existingItems, addedSession]
        } else {
          updatedPlaylist.items = [addedSession, ...existingItems]
        }
      }

      return {
        ...state,
        selectedPlaylist: state.selectedPlaylist.id === playlistId ? updatedPlaylist : state.selectedPlaylist,
        defaultPlaylist: playlist.default ? updatedPlaylist : state.defaultPlaylist,
        playlists: deepCopy(state.playlists).map((p) => {
          if (p.id === playlistId) {
            // this seems weird but if you replace the whole entry, it won't update components that are looking at it
            p.size = updatedPlaylist.size
            p.items = updatedPlaylist.items
          }
          return p
        }),
      }
    }
    case ACTION.BULK_ADD_TO_PLAYLIST: {
      const { playlistId, sessionIds } = value
      const playlist = state.playlists.find((p) => p.id === playlistId)
      const addedSessions = sessionIds.map((id) => ({ id }))
      const updatedPlaylist = {
        ...playlist,
        items: [...(playlist?.items ?? []), ...addedSessions],
        size: playlist.size + addedSessions.length,
      }
      return {
        ...state,
        selectedPlaylist: updatedPlaylist,
        defaultPlaylist: playlist.default ? updatedPlaylist : state.defaultPlaylist,
        playlists: [
          ...state.playlists.map((p) => {
            if (p.id === playlistId) {
              // this seems weird but if you replace the whole entry, it won't update components that are looking at it
              p.size = updatedPlaylist.size
              p.items = updatedPlaylist.items
            }
            return p
          }),
        ],
      }
    }
    case ACTION.REMOVE_FROM_PLAYLIST: {
      const playlist = state.playlists.find((p) => p.id === value.playlistId)
      const updatedItems = playlist.items ? [...playlist.items.filter((i) => i.id !== value.sessionId)] : null
      const updatedPlaylist = { ...playlist, items: updatedItems, size: updatedItems.length }

      return {
        ...state,
        selectedPlaylist: state.selectedPlaylist.id === value.playlistId ? updatedPlaylist : state.selectedPlaylist,
        defaultPlaylist: playlist.default ? updatedPlaylist : state.defaultPlaylist,
        playlists: [
          ...state.playlists.map((p) => {
            if (p.id === value.playlistId) {
              // this seems weird but if you replace the whole entry, it won't update components that are looking at it
              p.size = updatedPlaylist.size
              p.items = updatedPlaylist.items
            }
            return p
          }),
        ],
      }
    }
    case ACTION.SELECT_PLAYLIST: {
      const playlist = state.playlists.find((p) => p.id === value.playlistId)
      const series = state.series.find((p) => p.id === value.playlistId)
      const selected = playlist ?? series
      return {
        ...state,
        selectedPlaylist: selected,
      }
    }
    case ACTION.REORDER_PLAYLIST: {
      const { playlistId, items } = value
      const playlist = state.playlists.find((p) => p.id === playlistId)

      const updatedPlaylist = { ...playlist, items }
      return {
        ...state,
        selectedPlaylist: { ...playlist, items },
        defaultPlaylist: playlist.default ? updatedPlaylist : state.defaultPlaylist,
        playlists: [
          ...state.playlists.map((p) => {
            if (p.id === playlistId) {
              return updatedPlaylist
            }

            return p
          }),
        ],
      }
    }
    case ACTION.FOLLOW_PLAYLIST: {
      const { series } = value
      return { ...state, selectedPlaylist: series, series: [...state.series, series] }
    }
    default: {
      return initialState
    }
  }
}

export const PlaylistsContext = createContext()
export const usePlaylistsContext = () => useContext(PlaylistsContext)

export function PlaylistsProvider({ children }) {
  const user = useUser()

  const {
    getPlaylistItemsApi,
    getPlaylistsApi,
    createPlaylistApi,
    updatePlaylistApi,
    deletePlaylistApi,
    addToPlaylistApi,
    bulkAddToPlaylistApi,
    removeFromPlaylistApi,
    reorderPlaylistApi,
    getPlaylistsForItemApi,
    followPlaylistApi,
  } = usePlaylistsApi()
  const [{ playlists, defaultPlaylist, selectedPlaylist, series }, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    async function doLoad() {
      if (user.signedIn) {
        let results = []
        let offsetToken = null
        do {
          // eslint-disable-next-line no-await-in-loop
          const page = (await getPlaylistsApi(offsetToken)).data
          offsetToken = page.nextPageToken
          results = results.concat(page.results)
        } while (offsetToken)

        dispatch({ type: ACTION.PLAYLISTS_LOADED, value: { results } })
      }
    }
    doLoad()
  }, [getPlaylistsApi, user.signedIn])

  const createPlaylist = (playlist) => {
    return createPlaylistApi({ data: playlist }).then(({ data }) => {
      dispatch({ type: ACTION.CREATE_PLAYLIST, value: { playlist: data } })
      return data
    })
  }
  const followPlaylist = ({ seriesId }) => {
    return followPlaylistApi({ seriesId }).then(({ data }) => {
      dispatch({ type: ACTION.FOLLOW_PLAYLIST, value: { series: data } })
      return data
    })
  }

  const deletePlaylist = (playlist) => {
    return deletePlaylistApi({ playlistId: playlist.id }).then(() => {
      dispatch({ type: ACTION.DELETE_PLAYLIST, value: { playlistId: playlist.id, isFollower: !!playlist.parent } })
      return playlists
    })
  }

  const addToPlaylist = ({ playlistId, sessionId }) => {
    return addToPlaylistApi({ playlistId, sessionId }).then((response) => {
      dispatch({
        type: ACTION.ADD_TO_PLAYLIST,
        value: { playlistId, sessionId, addedAt: response.data.addedAt },
      })
      return selectedPlaylist
    })
  }

  const bulkAddToPlaylist = ({ playlistId, sessionIds }) => {
    return bulkAddToPlaylistApi({ playlistId, sessionIds }).then(() => {
      dispatch({ type: ACTION.BULK_ADD_TO_PLAYLIST, value: { playlistId, sessionIds } })
      return selectedPlaylist
    })
  }

  const removeFromPlaylist = ({ playlistId, sessionId }) => {
    return removeFromPlaylistApi({ playlistId, sessionId }).then(() => {
      dispatch({ type: ACTION.REMOVE_FROM_PLAYLIST, value: { playlistId, sessionId } })
      return selectedPlaylist
    })
  }

  const updatePlaylist = ({ playlistId, name, description }) => {
    return updatePlaylistApi({ playlistId, data: { name, description } }).then(() => {
      dispatch({ type: ACTION.EDIT_PLAYLIST, value: { playlistId, name, description } })
      return selectedPlaylist
    })
  }

  const reorderPlaylist = ({ playlistId, items }) => {
    return reorderPlaylistApi({ playlistId, ids: items.map((i) => i.id) }).then(() => {
      dispatch({ type: ACTION.REORDER_PLAYLIST, value: { playlistId, items } })
      return selectedPlaylist
    })
  }

  const selectPlaylist = useCallback(
    (playlist) => {
      dispatch({ type: ACTION.SELECT_PLAYLIST, value: { playlistId: playlist.id } })
      return selectedPlaylist
    },
    [selectedPlaylist]
  )

  const getPlaylistItems = useCallback(
    ({ playlistId, isSeries }) => {
      return getPlaylistItemsApi({ playlistId }).then(({ data }) => {
        dispatch({ type: ACTION.GET_PLAYLIST_ITEMS, value: { playlistId, items: data, isSeries } })
        return { data }
      })
    },
    [getPlaylistItemsApi]
  )

  const getPlaylistsForItem = useCallback(
    ({ sessionId, signedIn }) => {
      return getPlaylistsForItemApi({ sessionId, signedIn }).then(({ data }) => data.results)
    },
    [getPlaylistsForItemApi]
  )

  return (
    <PlaylistsContext.Provider
      value={{
        defaultPlaylist,
        selectedPlaylist,
        playlists,
        series,
        createPlaylist,
        deletePlaylist,
        updatePlaylist,
        addToPlaylist,
        bulkAddToPlaylist,
        removeFromPlaylist,
        reorderPlaylist,
        getPlaylistItems,
        selectPlaylist,
        getPlaylistsForItem,
        followPlaylist,
      }}
    >
      {children}
    </PlaylistsContext.Provider>
  )
}
