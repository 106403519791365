import React from 'react'
import { Row, Separator, H2, Paragraph } from '@fs/zion-ui'
import { i18n } from '@fs/zion-locale'
import { useTranslation } from 'react-i18next'
import { useFeatureFlag } from '@fs/zion-flags'
import { css } from '@emotion/core'
import { SectionCard, PillButton } from '../../../lib'
import { rarBackground } from '../../../images'
import { useIsMdWidth, useRequest } from '../../../hooks'
import { getEventAttendeeCounts } from '../../../api/orchestration'
import { relativesConfig } from '../../../config'

const SrOnly = css`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`

const Stat = ({ data, label }) => {
  const isMdWidth = useIsMdWidth()

  const Container = ({ children, ...props }) => (isMdWidth ? <div>{children}</div> : <Row {...props}>{children}</Row>)

  return (
    <>
      <span css={SrOnly}>
        {data} {label}
      </span>
      <Container alignY="middle" gutters="sm">
        <H2 supplementary aria-hidden>
          {data}
        </H2>
        <Paragraph size="sm" aria-hidden>
          {label}
        </Paragraph>
      </Container>
    </>
  )
}

const formatNumber = (num) => {
  return new Intl.NumberFormat(i18n.language).format(num)
}

function RelativesAtRootstechCard() {
  const [t] = useTranslation()
  const { loading, data, error } = useRequest(getEventAttendeeCounts)
  const isMdWidth = useIsMdWidth('sm')
  const previewFlag = useFeatureFlag('rootstech_preview')

  if (loading || error) return null

  const to = previewFlag.isOn ? relativesConfig.previewUrl : `/${i18n.language ?? 'en'}/connect`

  return (
    <SectionCard
      heading={t('all-access-registration.rar.subheader', 'Relatives at RootsTech')}
      description={t(
        'relatives-card.all-about',
        'RootsTech is all about connections.  Discover your connections with relatives around the world through Relatives at RootsTech.'
      )}
      image={rarBackground}
      flavor="flavorless"
      actions={
        <PillButton external to={to} linkName="Find Your Relatives">
          {t('relatives-card.find-your', 'Find Your Relatives')}
        </PillButton>
      }
    >
      <Separator size="xl" />
      <Stat
        data={formatNumber(data?.totalParticipants)}
        label={t('relatives-card.total-participants', 'TOTAL PARTICIPANTS')}
      />
      {isMdWidth && <Separator />}
      <Stat
        data={formatNumber(data?.countriesRepresented)}
        label={t('relatives-card.countries', 'COUNTRIES REPRESENTED')}
      />
    </SectionCard>
  )
}

export default RelativesAtRootstechCard
