import React from 'react'
import { Ellipsis, Row, Paragraph, Card, useContainerWidth, Bleed, WhiteTheme, H3, Separator } from '@fs/zion-ui'
import { Link } from '@fs/zion-router'
import { css } from '@emotion/core'
import VideoThumbnail from './VideoThumbnail'
import { SafeRichText, CreatorExhibitorLinks, getCustomImage, getPercentageWatched, StrongLink } from '../..'

const containerCss = css`
  display: flex;
  align-items: center;
`

const ThumbnailContainer = ({ shouldWrap, to, children, shrinkThumbnail, ...props }) => {
  if (shouldWrap) {
    return (
      <Bleed css={containerCss} top sides>
        <Link to={to} style={{ width: '100%' }} {...props}>
          {children}
        </Link>
      </Bleed>
    )
  }
  const minimumWidth = shrinkThumbnail ? 178 : 286
  return (
    <div css={containerCss} style={{ width: minimumWidth }}>
      <Card to={to} {...props}>
        <Bleed all>{children}</Bleed>
      </Card>
    </div>
  )
}

const BadgesContainer = ({ badges, timestamp, classroom, shouldWrap }) => {
  return shouldWrap ? (
    <div>
      <Row guttersX="none" alignX="end">
        {timestamp}
        {classroom}
      </Row>
      {badges}
    </div>
  ) : (
    <Row>
      <div growX>{badges} </div>
      <Row guttersX="none">
        {timestamp}
        {classroom}
      </Row>
    </Row>
  )
}

export default function CardListItem({
  actions,
  badges,
  classroom,
  creators,
  dataForVideo,
  description,
  exhibitors,
  heading,
  linkName,
  timestamp,
  to,
}) {
  const atWidth = useContainerWidth()
  const { video = {}, comingSoon, contentLocale, linkName: thumbnailLinkName, isSession, shrinkThumbnail } =
    dataForVideo || {}
  const { videoDuration } = video || {}

  const thumbnail = getCustomImage(dataForVideo)
  const percentageWatched = getPercentageWatched(dataForVideo)
  const hasCreatorsExhibitors = creators?.length > 0 || exhibitors?.length > 0
  const shouldWrap = atWidth({ default: true, md: false })

  return (
    <Card>
      <Row wrap={shouldWrap} alignX={shouldWrap ? 'center' : undefined}>
        {dataForVideo && (
          <ThumbnailContainer
            shouldWrap={shouldWrap}
            to={to}
            noShrinkX={!shouldWrap}
            growX={shouldWrap}
            linkName={thumbnailLinkName}
            shrinkThumbnail={shrinkThumbnail}
          >
            <VideoThumbnail
              thumbnail={thumbnail}
              duration={videoDuration}
              heading={heading}
              comingSoon={comingSoon}
              contentLocale={contentLocale}
              percentageWatched={percentageWatched}
              isSession={isSession}
            />
          </ThumbnailContainer>
        )}
        <div growX>
          <BadgesContainer badges={badges} timestamp={timestamp} classroom={classroom} shouldWrap={shouldWrap} />
          {(badges || timestamp || classroom) && <Separator size="xxs" />}
          <H3 size="H5" data-testid="list-item-title" supplementary>
            <WhiteTheme>
              <StrongLink to={to} noUnderline linkName={linkName}>
                {heading}
              </StrongLink>
            </WhiteTheme>
          </H3>
          <CreatorExhibitorLinks creators={creators} exhibitors={exhibitors} />
          <Paragraph size="sm">
            <Ellipsis as="span" lineClamp={hasCreatorsExhibitors ? 2 : 3} noTooltip>
              <SafeRichText as="span" dirtyInput={description} />
            </Ellipsis>
          </Paragraph>
          <Separator />
          {actions}
        </div>
      </Row>
    </Card>
  )
}
