import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from '@fs/zion-router'
import { useVendorChatOverlay, useIsMdWidth } from '../../hooks'
import PillButton from './PillButton'

export default function VendorChatButton({ vendorId, vendorName, imageUrl }) {
  const [t] = useTranslation()
  const chatOverlay = useVendorChatOverlay({ vendorId, vendorName, imageUrl })
  const isMdWidth = useIsMdWidth()
  const { query, removeQuery } = useQueryParams()
  const shouldOpenChat = Object.prototype.hasOwnProperty.call(query, 'showVendorChat')

  useEffect(() => {
    if (shouldOpenChat && chatOverlay) {
      // NOTE: chat overlay's handle click manages some internal context that is
      // important for this use case
      chatOverlay.handleClick()
      removeQuery('showVendorChat')
    }
  }, [chatOverlay, removeQuery, shouldOpenChat])

  return chatOverlay ? (
    <PillButton onClick={chatOverlay.handleClick} emphasis={isMdWidth ? 'high' : 'medium'} linkName="Vendor: Chat">
      {t('help.overlay.chat.primary', 'Chat')}
    </PillButton>
  ) : null
}

VendorChatButton.propTypes = {
  vendorId: PropTypes.string.isRequired,
  vendorName: PropTypes.string,
  imageUrl: PropTypes.string,
}
