import React from 'react'
import PropTypes from 'prop-types'
import { targetEnv } from '@fs/zion-config'
import { useLocation } from '@fs/zion-router'
import { useTranslation } from 'react-i18next'
import { PillButton } from '../../lib'
import { authConfig } from '../../config'

export default function SignInButton({ onClick, linkName, emphasis = 'high', children }) {
  const [t] = useTranslation()
  const { pathname, search } = useLocation()
  const basePath = '/rootstech'
  const returnUrl = `${targetEnv === 'local' ? pathname : `${basePath}${pathname}`}${search}`

  return (
    <PillButton
      size="sm"
      data-testid="sign-in-NavBar"
      to={`${authConfig.loginPath}?returnUrl=${encodeURIComponent(returnUrl)}`}
      external
      emphasis={emphasis}
      linkName={linkName}
      onClick={onClick}
      target="_self"
    >
      {children || t('common-ui:sign-in.action', 'Sign In')}
    </PillButton>
  )
}

SignInButton.propTypes = {
  /** Handler for button click */
  onClick: PropTypes.func,
  linkName: PropTypes.string,
}
