import React, { lazy, Suspense } from 'react'
import { ListItem, MenuOverlay, DialogOverlay, useOverlay, List, Separator } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { useFeatureFlag } from '@fs/zion-flags'
import { i18n } from '@fs/zion-locale'
import { PlaceWorld, PlaceHome, HelpAskQuestion, PlaceStore, DiscoveryHeritage } from '@fs/zion-icon'
import PropTypes from 'prop-types'
import { RainbowDivider, getNativeLanguageName, getProspectiveLanguages } from '../../lib'
import { useAnonSessionPermission, useIsMdWidth } from '../../hooks'
import MenuButton from './MenuButton'
import { AvatarMenuItems } from './AvatarMenuOverlay'
import { MiniEventsExpandableList } from './MiniEventsMenu'
import { RootstechEventExpandableList } from './RootstechEventMenu'
import { WatchVideosExpandableList } from './WatchVideosMenu'
import { AskUsAnythingButton } from '../../providers/AskUsAnythingProvider'

const LangPicker = lazy(() => import('@fs/zion-lang-picker'))

const HomeListItem = ({ dense = false, onClick }) => {
  const [t] = useTranslation()
  return (
    <ListItem
      data-testid="menu-home"
      to="/"
      primaryText={t('navigation.home.label', 'Home')}
      startElement={<ListItem.Icon Icon={PlaceHome} />}
      onClick={onClick}
      linkName="Menu: Home"
      dense={dense}
    />
  )
}
HomeListItem.propTypes = {
  dense: PropTypes.bool,
  onClick: PropTypes.func,
}

// onClick is used by MobileMenu to make sure the menu is closed when an item is selected
const MenuItems = ({ showNavItems, onClick, onLangClick, dense, removeFiller }) => {
  const userLocale = i18n.language
  const [t] = useTranslation()
  const langText = getNativeLanguageName(userLocale)
  const hideHomeNavFlag = useFeatureFlag('rootstech_hide_home_nav')
  const helpInMenuFlag = useFeatureFlag('rootstech_help_in_menu')
  const storeFlag = useFeatureFlag('rootstech_store')

  return (
    <>
      {showNavItems && (
        <>
          {!hideHomeNavFlag.isOn && <HomeListItem onClick={onClick} dense={dense} />}
          <RootstechEventExpandableList onClick={onClick} dense={dense} removeFiller={removeFiller} />
          {userLocale === 'en' && (
            <MiniEventsExpandableList onClick={onClick} dense={dense} removeFiller={removeFiller} />
          )}
          <WatchVideosExpandableList onClick={onClick} dense={dense} removeFiller={removeFiller} />
          <Separator size="nano" />
          <RainbowDivider full />
          <Separator size="nano" />
        </>
      )}
      {!getProspectiveLanguages().includes(i18n.language) && (
        <ListItem
          startElement={<ListItem.Icon Icon={HelpAskQuestion} />}
          data-testid="menu-faq"
          linkName="Menu: FAQ"
          onClick={onClick}
          primaryText={t('navigation.faq.label', 'FAQ')}
          to="/faq"
          dense={dense}
        />
      )}
      {storeFlag.isOn && (
        <ListItem
          startElement={<ListItem.Icon Icon={PlaceStore} />}
          data-testid="menu-rootstech-store"
          linkName="Menu: RootsTech Store"
          onClick={onClick}
          primaryText={t('navigation.rootstech-store.label', 'RootsTech Store')}
          to="https://rootstech.itemorder.com/shop/home/"
          external
          target="_blank"
          dense={dense}
        />
      )}
      {helpInMenuFlag.isOn && <AskUsAnythingButton onClick={onClick} asListItem dense={dense} />}
      <ListItem
        startElement={<ListItem.Icon Icon={DiscoveryHeritage} />}
        data-testid="menu-region"
        linkName="Menu: Region"
        onClick={onClick}
        to="/regions/"
        primaryText={t('navigation.region.label', 'Select Region/Country')}
        dense={dense}
      />
      <ListItem
        startElement={<ListItem.Icon Icon={PlaceWorld} />}
        data-testid="menu-language"
        linkName="Menu: Language"
        onClick={onLangClick}
        primaryText={t('navigation.language.label', 'Language — {lang}', {
          lang: langText,
        })}
        dense={dense}
      />
      {showNavItems && (
        <>
          <Separator size="nano" />
          <RainbowDivider full />
          <Separator size="nano" />
          <AvatarMenuItems onClick={onClick} dense={dense} />
        </>
      )}
    </>
  )
}
MenuItems.propTypes = {
  showNavItems: PropTypes.bool,
  onClick: PropTypes.func,
  onLangClick: PropTypes.func,
}

function MenuDialogOverlay({ showNavItems, onLangClick, hasPersonalDataPermission, dense, removeFiller = false }) {
  const [t] = useTranslation()
  const overlay = useOverlay()
  return (
    <>
      <MenuButton data-testid="mobile-menu-button" onClick={overlay.handleClick} active={overlay.isOpen} />
      {(overlay.isOpen || overlay.transitioning) && (
        <DialogOverlay {...overlay} fullscreen title={t('common-ui:menu.label', 'Menu')}>
          <List aria-label="Submenu">
            <MenuItems
              showNavItems={showNavItems}
              onClick={overlay.close}
              onLangClick={() => {
                overlay.close()
                onLangClick()
              }}
              hasPersonalDataPermission={hasPersonalDataPermission}
              dense={dense}
              removeFiller={removeFiller}
            />
          </List>
        </DialogOverlay>
      )}
    </>
  )
}
MenuDialogOverlay.propTypes = {
  showNavItems: PropTypes.bool,
  onLangClick: PropTypes.func,
  hasPersonalDataPermission: PropTypes.bool,
}

export default function Menu({ dense, showNavItems }) {
  const hasPersonalDataPermission = useAnonSessionPermission()
  const isMdWidth = useIsMdWidth()
  const langOverlay = useOverlay()
  const menuOverlay = useOverlay()

  return (
    <>
      {isMdWidth ? (
        <MenuDialogOverlay
          showNavItems={showNavItems}
          onLangClick={langOverlay.handleClick}
          hasPersonalDataPermission={hasPersonalDataPermission}
          dense={dense}
        />
      ) : (
        <>
          <MenuButton data-testid="menu-button" onClick={menuOverlay.handleClick} active={menuOverlay.isOpen} />
          {(menuOverlay.isOpen || menuOverlay.transitioning) && (
            <MenuOverlay {...menuOverlay} inline={false} aria-label="Submenu">
              <MenuItems
                showNavItems={showNavItems}
                onLangClick={langOverlay.handleClick}
                onClick={menuOverlay.close}
                hasPersonalDataPermission={hasPersonalDataPermission}
                dense={dense}
                removeFiller
              />
            </MenuOverlay>
          )}
        </>
      )}
      <Suspense fallback={<span />}>
        {(langOverlay.isOpen || langOverlay.transitioning) && <LangPicker overlay={langOverlay} />}
      </Suspense>
    </>
  )
}
Menu.propTypes = {
  showNavItems: PropTypes.bool,
}
