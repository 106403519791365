import React from 'react'
import { i18n } from '@fs/zion-locale'
import { useFeatureFlag } from '@fs/zion-flags'
import { useTranslation } from 'react-i18next'
import SiteBanner from '../SiteBanner/SiteBanner'
import { useAnonSessionPermission } from '../../../hooks'

// eslint-disable-next-line no-bitwise
const getHashFromString = (s) => s.split('').reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0)

export default function NoticeBanner({ heading, to = '', linkName = '', buttonText = '' }) {
  const [t] = useTranslation()
  const englishOnlyFlag = useFeatureFlag('rootstech_english_only_banner')
  const hasPersonalDataPermission = useAnonSessionPermission()
  const shouldntRender = !hasPersonalDataPermission || (englishOnlyFlag.isOn && i18n.language !== 'en')

  const confirmProps = {
    'data-testid': 'action',
    to,
    linkName,
    children: buttonText,
    hidden: !buttonText,
  }
  const dismissProps = {
    children: t('site-banner.dismiss.action', 'Dismiss'),
    emphasis: 'low',
    size: 'sm',
  }

  return shouldntRender ? null : (
    <SiteBanner
      heading={heading}
      storageKey={getHashFromString(to + linkName)}
      isMarketing
      type="info"
      confirmProps={confirmProps}
      dismissProps={dismissProps}
    />
  )
}
