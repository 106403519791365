import React from 'react'
import { useTranslation } from 'react-i18next'
import { PromoBanner } from '../../../lib'
import { rootsTechByFamilySearchSvg } from '../../../images'

function WhatIsRootsTechBanner() {
  const [t] = useTranslation()
  return (
    <PromoBanner
      heading={t('what-is-rootstech.title', 'What is RootsTech?')}
      description={t(
        'what-is-rootstech.body',
        'RootsTech is a place to learn, to be inspired, and make connections through family history.'
      )}
      flavor="blueberry"
      image={rootsTechByFamilySearchSvg}
      buttonText={t('common-ui:learn-more.action', 'Learn More')}
      to="/about"
    />
  )
}

export default WhatIsRootsTechBanner
