import React, { useEffect } from 'react'
import { useHistory } from '@fs/zion-router'
import ZionForm from '@fs/zion-form'
import { ContentCheck } from '@fs/zion-icon'
import { useTranslation } from 'react-i18next'
import { DialogOverlay, DialogOverlayActions, useStatusOverlay } from '@fs/zion-ui'
import { useFeatureFlag } from '@fs/zion-flags'
import PillButton from '../../../lib/buttons/PillButton' // Needs to be direct or circular dep.
import PlaylistTitleTextField from './PlaylistTitleTextField'
import PlaylistDescriptionTextField from './PlaylistDescriptionTextField'
import usePlaylistForm from '../hooks/usePlaylistForm'
import { usePlaylistsContext, usePlaylistsContextV2 } from '../../../providers'
import { playlistsConfig } from '../../../config'
import notifySentry from '../../../lib/helpers/sentryNotifier'

export default function CreatePlaylistOverlay({ sessionId, onPlaylistCreated, ...overlay }) {
  const [t] = useTranslation()
  const history = useHistory()
  const improvedUxEnabled = useFeatureFlag('rootstech_playlists_improved_ux').isOn
  const { createPlaylist: createPlaylistV1 } = usePlaylistsContext()
  const { createPlaylist: createPlaylistV2 } = usePlaylistsContextV2()
  const { methods } = usePlaylistForm({ name: '', description: '' })
  const showStatusOverlay = useStatusOverlay()

  useEffect(() => methods.setFocus('name'))

  const onSubmit = ({ name, description }) => {
    const createPlaylist = improvedUxEnabled ? createPlaylistV2 : createPlaylistV1
    createPlaylist({ name, description, initialItem: sessionId || undefined })
      .then((results) => {
        methods.reset(undefined, { keepValues: false })

        showStatusOverlay({
          message: `${t('playlists.alerts.created-new', 'Created playlist { playlistName }.', { playlistName: name })}`,
          type: 'success',
          Icon: ContentCheck,
          actionLabel: t('playlists.saved.nav-to-playlist', 'Go to Playlist'),
          actionCallback: () => history.push(`/playlists/${results.id}`),
        })

        onPlaylistCreated?.(results)
      })
      .then(() => !improvedUxEnabled && overlay.close())
      .catch((err) => {
        let message = t(
          'playlists.errors.generic.creation-error',
          'We’re sorry, but there was an issue creating your new playlist. Please try again shortly or refresh your browser.'
        )
        if (err.response.status === 422) {
          message = t(
            'playlists.alerts.playlist-limit-reached',
            'You have reached the maximum limit of {max} playlists. Please delete any old or non-essential playlists to make room for new ones.',
            {
              max: playlistsConfig.playlistLimit,
            }
          )
        } else {
          notifySentry({ ...err.response, err })
        }

        showStatusOverlay({
          message,
          type: 'error',
          dismissable: true,
        })
      })
  }
  return (
    <DialogOverlay
      autoFocus
      {...overlay}
      title={t('playlists.create.title', 'Create New Playlist')}
      closeOnClickAway={false}
      footer={
        <DialogOverlayActions>
          <PillButton emphasis="medium" type="submit" Icon={ContentCheck} linkName="Save New Playlist">
            {t('common-ui:save.action', 'Save')}
          </PillButton>
        </DialogOverlayActions>
      }
      as={ZionForm}
      methods={methods}
      onSubmit={onSubmit}
    >
      <PlaylistTitleTextField />
      <PlaylistDescriptionTextField />
    </DialogOverlay>
  )
}
