import React from 'react'
import { Separator, Subheading, WhiteTheme, H2, Overline } from '@fs/zion-ui'
import RainbowImageBand from '../RainbowImageBand'
import { SpeakerCardGrid } from '../card'
import { SectionDescription } from '../section-card'

export default function SpeakerListSection({
  flavor = 'mango',
  speakers,
  overline,
  heading,
  subHeading,
  description,
  bold = true,
}) {
  const formattedHeading = bold ? <strong>{heading}</strong> : heading

  return (
    <RainbowImageBand as="section" minHeight={494} alignY="bottom" flavor={flavor} marginX="md" marginY="xl" noScrim>
      <Separator size="lg" />
      <H2 supplementary centered>
        {overline && <Overline>{overline}</Overline>}
        {formattedHeading}
        {subHeading && (
          <Subheading>
            <WhiteTheme>{subHeading}</WhiteTheme>
          </Subheading>
        )}
      </H2>
      {description && (
        <>
          <Separator size="xs" />
          <SectionDescription fullWidth bold={bold} centered>
            {description}
          </SectionDescription>
        </>
      )}
      <Separator size="lg" />
      <SpeakerCardGrid speakers={speakers} />
      <Separator size="lg" />
    </RainbowImageBand>
  )
}
