import notifySentry from './sentryNotifier'

export const isObject = (obj) => {
  if (typeof obj === 'object' && !Array.isArray(obj) && obj !== null) {
    return true
  }
  return false
}

/**
 * sanitizeProps from frontend friends is too strict (It'll throw errors on a ny blacklisted props). This will just filter those out
 * @param {Array} blackList and array of keys to filter out
 */
export const softSanitizeProps = (props, blackList) => {
  const sanitizedProps = Object.fromEntries(Object.entries(props).filter(([key]) => !blackList.includes(key)))
  return sanitizedProps
}

export const chunkArray = (array, limit) => {
  const chunks = []
  const arr = [...array]
  while (arr.length > 0) {
    chunks.push(arr.splice(0, limit))
  }
  return chunks
}

export const isDeepEqual = (x, y) => {
  if (x === y) {
    return true
  }

  if (typeof x !== 'object' || typeof y !== 'object' || x === null || y === null) {
    return false
  }

  const keysX = Object.keys(x)
  const keysY = Object.keys(y)

  if (keysX.length !== keysY.length) {
    return false
  }

  return !keysX.some((key) => !isDeepEqual(x[key], y[key]))
}

export const removeDuplicatesByKey = (arr, key) => {
  const uniqueItems = []
  const keys = new Set()

  arr.forEach((item) => {
    const itemKey = item[key]

    if (!keys.has(itemKey)) {
      keys.add(itemKey)
      uniqueItems.push(item)
    }
  })

  return uniqueItems
}

export const deepCopy = (obj) => {
  if (window.structuredClone) {
    return window.structuredClone(obj)
  }
  return JSON.parse(JSON.stringify(obj))
}

// If we are traversing an object that is over 100 layers deep,
// there is probably something wrong. In my testing, the deepest I saw was 7.
const MAX_LEVELS_DEEP = 100
const traverse = (obj, callback, numberOfLevelsDeep = 0) => {
  if (numberOfLevelsDeep > MAX_LEVELS_DEEP) {
    throw new Error('Recursion went too deep, preventing an infinite loop')
  }
  if (Array.isArray(obj)) {
    obj.forEach((value) => traverse(value, callback, numberOfLevelsDeep + 1))
  } else if (isObject(obj)) {
    callback(obj)
    Object.values(obj).forEach((value) => traverse(value, callback, numberOfLevelsDeep + 1))
  }
}

export const traverseAndModify = (data, condition, modifier) => {
  const clonedData = deepCopy(data)
  try {
    traverse(clonedData, (obj) => {
      if (condition(obj)) {
        modifier(obj)
      }
    })
  } catch (error) {
    notifySentry(error)
  }
  return clonedData
}

export const isArrayEqual = (array1, array2) => {
  if (array1.length !== array2.length || !Array.isArray(array1) || !Array.isArray(array2)) {
    return false
  }

  return array1.every((value) => array2.includes(value))
}
