import React, { createContext, useContext, lazy, Suspense, useEffect, useState } from 'react'
import { useOverlay } from '@fs/zion-ui'
import { useChatOverlay, CHAT_ROUTE_KEY, buildChatRoute } from '@fs/zion-chat'
import { useRouteMatch } from '@fs/zion-router'
import LoadingSpinner from '../../lib/LoadingSpinner'

const AskUsAnythingOverlay = lazy(() => import('./AskUsAnythingOverlay/AskUsAnythingOverlay'))

const AskUsAnythingContext = createContext()
export const useAskUsAnythingContext = () => useContext(AskUsAnythingContext)

const chatRoute = buildChatRoute(CHAT_ROUTE_KEY.CREATE_SUPPORT_CHAT)

export const AskUsAnythingProvider = ({ children }) => {
  const isHelpRoute = Boolean(useRouteMatch('/help'))
  const overlay = useOverlay({ initiallyOpen: isHelpRoute })
  const chatOverlay = useChatOverlay(chatRoute)

  // Because this is in a provider above the routes, navigating from within the
  // app to /help will not trigger the overlay by just setting the initiallyOpen
  // prop
  const [isHelpOpened, setIsHelpOpened] = useState()
  useEffect(() => {
    if (isHelpRoute && !isHelpOpened) {
      overlay.open()
      setIsHelpOpened(true)
    }
    if (!isHelpRoute) {
      setIsHelpOpened(false)
    }
  }, [overlay, isHelpRoute, isHelpOpened])

  const chatHandler = (e) => {
    chatOverlay.handleClick(e)
  }
  return (
    <AskUsAnythingContext.Provider value={{ overlay }}>
      {children}

      <Suspense fallback={<LoadingSpinner />}>
        {(overlay.isOpen || overlay.transitioning) && (
          <AskUsAnythingOverlay chatHandler={chatHandler} chatRoute={chatRoute} {...overlay} />
        )}
      </Suspense>
    </AskUsAnythingContext.Provider>
  )
}
