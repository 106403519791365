import React, { Children } from 'react'
import { FlowGrid, useContainerWidth } from '@fs/zion-ui'
import SpeakerCard from './SpeakerCard'

export default function SpeakerCardGrid({ speakers }) {
  const atWidth = useContainerWidth()
  return speakers ? (
    <FlowGrid
      gutters={atWidth({ default: 'md', xxl: 'xl' })}
      columnCount={atWidth({ default: 2, sm: 4, xl: 6 })}
      lastRowAlignX="center"
    >
      {Children.toArray(
        speakers.map((s) => <SpeakerCard title={s.title} description={s.description} url={s.url} image={s.image} />)
      )}
    </FlowGrid>
  ) : null
}
