import { second, minute } from './time'
import { brightcoveConfig } from './video'

const expoHallConfig = {
  video: {
    providerType: 'brightcove',
    videoId: '6321333565112',
    duration: 2 * minute + 1 * second,
    accountId: brightcoveConfig.master.accountId,
  },
  locales: ['en', 'de', 'fr', 'it'],
  searchTerms: [
    'Charts',
    'DNA',
    'Professionals',
    'Storytelling',
    'Travel',
    'Records',
    'Research',
    'Preservation',
    'Family History',
    'Technology',
    'Connection',
    'Tree Chart',
    'Storytelling',
    'Search Family',
    'Society',
    'Association',
    'Library',
  ],
}
const boothConfig = {
  platinumSponsors: ['ancestry', 'familysearch', 'myheritage', 'storied'],
  companiesExcludedInChat: [
    'MyHeritage',
    'Ancestry®',
    'Ancestry',
    'Sessions for Members of The Church of Jesus Christ of Latter-day Saints',
    'South Asia',
    'RootsTech España',
    'New Zealand Research',
    'नेपाली सामग्री (Nepali)',
    'Family Tree Maker',
  ],
}

export { expoHallConfig, boothConfig }
