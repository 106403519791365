import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { getBsCountries } from '../../../api'
import { useErrorHandlerOverlay } from '../../../hooks'

export default function useCountries() {
  const [countries, setCountries] = useState([])
  const { loading, data, error } = useQuery(getBsCountries(), { skip: countries?.length > 0 })
  useEffect(() => {
    if (!loading && data) {
      const unsortedCountries = [...data.ConferenceDataViewModel.countries]
      const sortedCountries = unsortedCountries.sort((first, second) => `${first.label}`.localeCompare(second.label))
      setCountries(sortedCountries)
    }
  }, [data, loading])
  useErrorHandlerOverlay(error)

  return { loading, countries, error }
}
