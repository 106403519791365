import { useState, useEffect } from 'react'
import { isDeepEqual } from '../../lib'

/**
 * If you need to have an object as a dep for a useEffect, this allows for a stable signature so we don't cause an infinite loop
 */
export default function useStableObject(props) {
  const [state, setState] = useState(props)

  useEffect(() => {
    if (!isDeepEqual(props, state)) {
      setState(props)
    }
  }, [props, state])

  return state
}
