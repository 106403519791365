import { ProfileImage } from './ImageDetail'
import { ConferencePromo } from './ConferencePromo'
import SocialAccount from './SocialAccount'
import ObjectTypeDetail from './ObjectTypeDetail'
import ContentVisibility from './ContentVisibility'
import ContactInformation from './ContactInformation'

const CreatorDetail = `
  ${ProfileImage}
  ${SocialAccount}
  ${ObjectTypeDetail}
  ${ContactInformation}
  ${ContentVisibility}
  ${ConferencePromo}
  fragment CreatorDetail on CreatorDetail {
    alternateLocales
    associatedUser
    biography
    company
    contentLocale
    creatorType
    honors
    id
    name
    profileImage {
      ...ProfileImage
    }
    secondaryLocale
    socialAccounts {
      ...SocialAccount
    }
    submissions {
      ...ConferencePromo
    }
    title
    contactInformation {
      ...ContactInformation
    }
    url
    visibility {
      ...ContentVisibility
    }
  }
`
export default CreatorDetail
