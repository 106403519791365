import React, { forwardRef } from 'react'
import { Card, MarginBox, WhiteTheme, Row, Paragraph, Separator } from '@fs/zion-ui'
import PropTypes from 'prop-types'
import { useIsMdWidth } from '../../hooks'

function AddCardButton({ to, Icon, children, disabled, ...props }, ref) {
  const isMdWidth = useIsMdWidth()
  if (to) {
    props.noLinkStyles = true
  }
  return isMdWidth ? (
    <Card ref={ref} to={to} {...props}>
      <WhiteTheme>
        <Row alignX="center" alignY="middle" gutters="xs">
          {Icon && <Icon size="lg" />}
          <Paragraph>{children}</Paragraph>
        </Row>
      </WhiteTheme>
    </Card>
  ) : (
    <Card data-testid="card" {...props} ref={ref} to={to}>
      <div growY />
      <WhiteTheme>
        <MarginBox marginY="xxs" marginX="xxs">
          <Row alignX="center">{Icon && <Icon size="lg" />}</Row>
          <Row alignX="center">
            <Paragraph centered>{children}</Paragraph>
          </Row>
        </MarginBox>
      </WhiteTheme>
      <Separator />
      <div growY />
    </Card>
  )
}

export default forwardRef(AddCardButton)

AddCardButton.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]).isRequired,
  Icon: PropTypes.elementType,
}
