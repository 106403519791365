import { gql } from '@apollo/client'
import { CountryDataReference } from './fragments'

export default function getBsCountries() {
  return gql(`
    ${CountryDataReference}
    query QueryCountries {
      ConferenceDataViewModel {
        countries {
          ...CountryDataReference
        }
      }
    }
  `)
}
