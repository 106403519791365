import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import {
  LayoutBand,
  WhiteTheme,
  Image,
  Bleedable,
  MarginBox,
  Ellipsis,
  Separator,
  H2,
  Overline,
  Subheading,
} from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { boolAttr } from '@fs/zion-frontend-friends'
import { useIsMdWidth } from '../../hooks'
import { PillButton } from '../buttons'
import { isExternalUrl } from '../helpers'
import SafeRichText from '../SafeRichText'
import { flavors } from '../../config'

const preTextCss = css`
  color: #ffffff;
  min-width: 110px;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
`
const imageCircleCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 225px;
  height: 225px;
  margin: -30px auto;
  background: #0000001a;
  padding: 0 25px;
  border-radius: 50%;
`
const layoutCss = css`
  overflow: hidden;
  border-radius: 32px;
  background: var(--flavor-gradient);

  &[is-md-width] {
    text-align: center;
    border-radius: 16px;
  }
`
const containerCss = css`
  display: flex;
  align-items: center;

  &[is-md-width] {
    flex-direction: column;
  }
`
const textContainerCss = css`
  width: 65%;
  margin: auto;

  &[is-md-width] {
    width: initial;
  }
`

export default function PromoBanner({
  preText,
  image,
  overline,
  heading,
  subHeading,
  description,
  buttonText,
  flavor = flavors.default,
  scrim = true,
  onClick,
  to,
  children,
  ...props
}) {
  const isMdWidth = useIsMdWidth()
  const [t] = useTranslation()

  const shouldShowPretext = preText && !isMdWidth
  const external = isExternalUrl(to)
  return (
    <Bleedable sides="24">
      <LayoutBand
        as="section"
        style={{
          '--flavor-gradient': flavors[flavor].gradient,
          '--scrim-color': scrim ? '#0000008c' : 'transparent',
        }}
        css={layoutCss}
        is-md-width={boolAttr(isMdWidth)}
        {...props}
      >
        <MarginBox marginY="xs" marginX="sm">
          <div css={containerCss} is-md-width={boolAttr(isMdWidth)}>
            {shouldShowPretext && (
              <div aria-hidden css={preTextCss}>
                {preText}
              </div>
            )}
            {image && (
              <div css={imageCircleCss} data-testid="promo-image">
                <Image src={image} alt={t('contributor-overlay.contributor.type.sponsor', 'Sponsor')} loading="lazy" />
              </div>
            )}
            <div css={textContainerCss} is-md-width={boolAttr(isMdWidth)}>
              <WhiteTheme>
                <H2 size="H5" data-testid="promo-header">
                  <Overline>{overline}</Overline>
                  {heading}
                  <Subheading>{subHeading}</Subheading>
                </H2>
                <Separator size="nano" />
                {description && <SafeRichText as={Ellipsis} lineClamp={2} noTooltip dirtyInput={description} />}
              </WhiteTheme>
              {to && (
                <>
                  <Separator size="xxs" />
                  <PillButton
                    onClick={onClick}
                    to={to}
                    external={external}
                    target={external ? '_blank' : '_self'}
                    emphasis="medium"
                    linkName={`${heading} : Promo`}
                    data-testid="promo-button"
                  >
                    {buttonText}
                  </PillButton>
                </>
              )}
            </div>
          </div>
        </MarginBox>
      </LayoutBand>
    </Bleedable>
  )
}

PromoBanner.propTypes = {
  /** Heading of the banner. */
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /** The image element to show in the banner. */
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /** Text shown before the images. */
  preText: PropTypes.string,
  /** The description of the banner. */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** The text to show for the action button. */
  buttonText: PropTypes.string,
  /** The flavor to show as the background. */
  flavor: PropTypes.string,
  /** The function to call when the button is clicked. */
  onClick: PropTypes.func,
}
