import { useCallback } from 'react'
import { useChatOverlay, buildChatRoute, CHAT_ROUTE_KEY } from '@fs/zion-chat'
import { useQueryParams } from '@fs/zion-router'
import useHelpDesk from './useHelpDesk'
import { eventConfig } from '../config'
import { useRequest } from './useRequest'

export default function useVendorChatOverlay({ vendorId, vendorName = '', imageUrl = '' }) {
  const { getVendorReps } = useHelpDesk()
  const { query } = useQueryParams()
  const previewId = query?.previewId

  const getReps = useCallback(() => {
    if (!vendorId && previewId) {
      return () => ({})
    }

    return getVendorReps(vendorId, eventConfig.conference).then((data) => ({ data, loading: false }))
  }, [getVendorReps, previewId, vendorId])

  const { data, loading } = useRequest(getReps)

  const supportChatInformation = { name: vendorName, imageUrl }
  if (data?.length > 0) {
    supportChatInformation.to = data
  }
  const chatOverlay = useChatOverlay(buildChatRoute(CHAT_ROUTE_KEY.CREATE_VENDOR_CHAT), supportChatInformation)
  if (data?.length > 0) {
    return loading ? null : chatOverlay
  }
  return null
}
