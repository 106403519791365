import React, { Children } from 'react'
import { Paragraph, Separator } from '@fs/zion-ui'
import RainbowCard from '../RainbowCard'
import NoBulletList from '../NoBulletList'
import BulletList from '../BulletList'
import SafeRichText from '../SafeRichText'

export default function FeatureCard({
  image,
  noScrim = true,
  heading,
  flavor,
  highlightItem,
  listItems,
  actions,
  bullets,
  description,
}) {
  const List = bullets ? BulletList : NoBulletList

  return (
    <RainbowCard image={image} noScrim={noScrim} heading={heading} flavor={flavor}>
      {highlightItem && (
        <Paragraph>
          <strong>{highlightItem}</strong>
        </Paragraph>
      )}
      {listItems ? (
        <List>
          {Children.toArray(
            listItems.map((item) => (
              <Paragraph as="li" size="sm">
                {item}
              </Paragraph>
            ))
          )}
        </List>
      ) : (
        <SafeRichText size="sm" dirtyInput={description} />
      )}
      <Separator />
      {actions}
    </RainbowCard>
  )
}
