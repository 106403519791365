import React from 'react'
import { IconButton, colors } from '@fs/zion-ui'
import { css } from '@emotion/core'

const iconButtonHackCss = css`
  & button,
  & a {
    --button-text-color: ${colors.gray60};
    --button-background: ${colors.gray10};
    --button-hover-background: ${colors.transparent.gray30};
    --button-active-background: ${colors.transparent.gray10};
  }
`

export default function TabletButton({ ...props }) {
  return (
    <div css={iconButtonHackCss}>
      <IconButton color="gray" {...props} />
    </div>
  )
}
