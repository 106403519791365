import React, { forwardRef } from 'react'
import { Button, IconButton } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { Menu as MenuIcon } from '@fs/zion-icon'
import { css } from '@emotion/core'
import { boolAttr } from '@fs/zion-frontend-friends'
import { useIsMdWidth } from '../../hooks'
import { flavors } from '../../config'

const buttonOverrideCss = css`
  position: relative;

  & button {
    color: #ffffff !important;
    font-weight: 600;

    :hover {
      background: none !important;
    }
  }

  ::after {
    content: '';
    height: 4px;
    width: 0;
    background: ${flavors.blueberry.solid};
    opacity: 0.5;
    position: absolute;
    bottom: -4px;
    border-radius: 4px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: 0.1s;
  }
  :hover,
  :focus {
    color: #ffffff;
    background: none !important;
    ::after {
      width: 50% !important;
    }
  }
  &[active] {
    border: none !important;
    color: #ffffff;
    &::after {
      width: 100%;
      opacity: 1;
    }
  }
`

const MenuButton = forwardRef(({ active, ...props }, ref) => {
  const [t] = useTranslation()
  const isMdWidth = useIsMdWidth()
  const buttonProps = {
    ...props,
    Icon: MenuIcon,
    'aria-label': t('common-ui:menu.label', 'Menu'),
    linkName: 'Menu',
    ref,
  }
  return (
    <div css={buttonOverrideCss} active={boolAttr(active)}>
      {isMdWidth ? (
        <IconButton {...buttonProps} size="xs" />
      ) : (
        <Button {...buttonProps}>{t('common-ui:menu.label', 'Menu')}</Button>
      )}
    </div>
  )
})

export default MenuButton
