import CountryDataReference from './CountryDataReference'

const ContactInformation = `
  ${CountryDataReference}
  fragment ContactInformation on CreatorContactInformation {
    email
    country {
      ...CountryDataReference
    }
  }
`
export default ContactInformation
