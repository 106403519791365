import React from 'react'
import { css } from '@emotion/core'
import { BillboardButton, colors } from '@fs/zion-ui'
import PropTypes from 'prop-types'
import { boolAttr } from '@fs/zion-frontend-friends'
import { isExternalUrl } from '../helpers/urlHelpers'

const buttonHackCss = css`
  & {
    button,
    a {
      border-radius: 2em;
      text-transform: none;
      font-weight: 600;
      font-size: var(--text-size-b);
      white-space: normal !important;
      overflow: visible;
      word-break: unset;
      max-width: fit-content;
      text-align: center;
      height: auto;
      padding-bottom: 13px;
    }
  }

  &[bigness='sm'] {
    button,
    a {
      display: block !important;
    }
  }

  &:not([use-billboard-color]) {
    button,
    a {
      :not([aria-disabled='true']) {
        --button-text-color: white;
        --button-hover-background: ${colors.transparent.gray05};
        --button-active-background: ${colors.transparent.gray10};
      }
    }

    &[emphasis='high'] {
      button,
      a {
        :not([aria-disabled='true']) {
          --button-hover-background: ${colors.gray80};
          --button-background: white;
          --button-text-color: black;
          --elev-shadow: 0;
          --elev-veil: 0;

          :hover:not(:aria-disabled=true):not(:active) {
            --elev-shadow: 0;
            --elev-veil: 0;
          }
        }
      }
    }
  }
`

const PillButton = ({ emphasis = 'high', useBillboardColor, size = 'lg', children, to, ...props }) => {
  const external = to ? isExternalUrl(to) : false

  return (
    <div css={buttonHackCss} emphasis={emphasis} bigness={size} use-billboard-color={boolAttr(useBillboardColor)}>
      <BillboardButton
        emphasis={emphasis}
        external={to ? external : undefined}
        target={external ? '_blank' : '_self'}
        to={to}
        {...props}
      >
        {children}
      </BillboardButton>
    </div>
  )
}

PillButton.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  Icon: PropTypes.elementType,
  emphasis: PropTypes.oneOf(['low', 'medium', 'high']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
}

export default PillButton
