import React, { Suspense, lazy } from 'react'
import { css } from '@emotion/core'
import { colors, useOverlay } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { i18n } from '@fs/zion-locale'
import { getNativeLanguageName } from '../../lib'
import NavLink from './NavLink'

const LangPicker = lazy(() => import('@fs/zion-lang-picker'))

const linkCss = css`
  &::before {
    content: ' ';
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAWlBMVEUAAAB3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3cyECA3AAAAHXRSTlMApeAO+po1/H/uyXcZCvPortXBi04luZ9xVz4EE05v0OMAAACjSURBVBjTZU9JEoMwDJNDQgJZCDuU+v/frJ1OT9WMD14kWVD0zlhrXI8vOuKBjGVm6lo/8nDeNU119aNOSFYlLu8daWYSPiumsAO5Fu7hipd+b+QzWgdDcUkXFE5ODWx4wpqavBpZ+KBGJT7AVQ1boQCYmOcXgFsobsjAJsIJaumkhIOZVwiC2IL8BpglQw+pve5DPkQhB3n9F+5wkYYW7i/+B2iSCWl6HoSJAAAAAElFTkSuQmCC')
      top left no-repeat;
    padding-inline-start: 24px;
    padding-bottom: 1px;
  }
`

const loadingCss = css`
  color: ${colors.transparent.gray70};
  font-size: 0.875rem;
  line-height: 1.35;
  margin-inline-end: 25px;
  background: transparent;
  border: none;
  font-family: Verdana, Ayuthaya, sans-serif;

  &::before {
    content: '';
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAWlBMVEUAAAB3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3cyECA3AAAAHXRSTlMApeAO+po1/H/uyXcZCvPortXBi04luZ9xVz4EE05v0OMAAACjSURBVBjTZU9JEoMwDJNDQgJZCDuU+v/frJ1OT9WMD14kWVD0zlhrXI8vOuKBjGVm6lo/8nDeNU119aNOSFYlLu8daWYSPiumsAO5Fu7hipd+b+QzWgdDcUkXFE5ODWx4wpqavBpZ+KBGJT7AVQ1boQCYmOcXgFsobsjAJsIJaumkhIOZVwiC2IL8BpglQw+pve5DPkQhB3n9F+5wkYYW7i/+B2iSCWl6HoSJAAAAAElFTkSuQmCC')
      top left no-repeat;
    padding-inline-start: 24px;
    padding-bottom: 1px;
  }
`

export default function LanguageLink({ ...props }) {
  const overlay = useOverlay()
  const { t } = useTranslation()
  const langText = getNativeLanguageName(i18n.language)

  return (
    <>
      <Suspense fallback={<span css={loadingCss}>{t('common-ui:loading.ellipsis.label', 'Loading...')}</span>}>
        <NavLink text={langText} linkName="Footer: Language" onClick={overlay.handleClick} css={linkCss} {...props} />
        {(overlay.isOpen || overlay.transitioning) && <LangPicker overlay={overlay} />}
      </Suspense>
    </>
  )
}
