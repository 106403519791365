import React, {
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useState,
  Suspense,
  useContext,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ResponsiveProvider, WidthContext } from '@fs/zion-ui/quarks-authorized-use-only'
import { trackLink } from '@fs/zion-analytics'
import { i18n } from '@fs/zion-locale'
import { stageWithPlaybuttonJpg, brightcovePlayButtonPng } from '../../../../images'
import { getISO639_2LanguageCode } from '../../../helpers/languageHelpers'
import useBitmovinPlayerAnalytics from './useBitmovinPlayerAnalytics'

const BITMOVIN_SCRIPT = 'https://psdcdn.churchofjesuschrist.org/bitmovin-player.min.js'

const noop = () => {}

const BitmovinPlayer = forwardRef(
  (
    {
      videoData: { videoId, accountId },
      onPlayerError = noop,
      onPlay = noop,
      onPause = noop,
      onPlayerReady = noop,
      onEnd = noop,
      autoplay = false,
      muted = false,
      seekStartTime, // Don't want this in the DOM.
      strings: {
        playerError = 'There was an error in the video player.... [System information: This was a problem in the Bitmovin player script.]',
      } = {},
      title,
      ...rest
    },
    ref
  ) => {
    const container = useRef(null)
    const playerErrorCallback = useCallback(onPlayerError, []) // eslint-disable-line react-hooks/exhaustive-deps
    const playCallback = useCallback(onPlay, []) // eslint-disable-line react-hooks/exhaustive-deps
    const pauseCallback = useCallback(onPause, []) // eslint-disable-line react-hooks/exhaustive-deps
    const playerReadyCallback = useCallback(onPlayerReady, []) // eslint-disable-line react-hooks/exhaustive-deps
    const endCallback = useCallback(onEnd, []) // eslint-disable-line react-hooks/exhaustive-deps
    const width = useContext(WidthContext)
    const containerId = `bitmovin-player-${accountId?.trim()}-${videoId?.trim()}`

    useImperativeHandle(ref, () => {
      return {
        play: () => window.bitmovin[containerId].play(),
        pause: () => window.bitmovin[containerId].pause(),
      }
    })

    useEffect(() => {
      let script = document.querySelector(`script[src="${BITMOVIN_SCRIPT}"]`)

      if (!script) {
        script = document.createElement('script')
        script.src = BITMOVIN_SCRIPT
        script.async = true
        document.head.appendChild(script)
      }

      const playerInfo = {
        dataAccount: accountId,
        dataVideoId: videoId,
        containerId,
        cast: { enable: true },
        remotecontrol: { type: 'googlecast' },
        style: { aspectratio: '16:9' },
        playback: {
          autoplay,
          muted,
          blockDownloads: true,
        },
        events: {
          playbackfinished: {
            function: () => {
              endCallback(window.bitmovin[containerId])
            },
          },
          play: { function: playCallback },
          paused: {
            function: () => {
              pauseCallback(window.bitmovin[containerId])
            },
          },
          ready: {
            function: () => {
              playerReadyCallback(window.bitmovin[containerId])
            },
          },
          error: { function: playerErrorCallback },
          seek: { function: noop },
        },
      }

      if (window.bitmovin) {
        if (container.current) {
          container.current.innerHTML = ''
        }
        window.bitmovin.buildPlayer(playerInfo)
      } else {
        script.addEventListener('load', () => {
          window.bitmovin.buildPlayer(playerInfo)
        })
        script.addEventListener('error', (event) => {
          if (container.current) {
            container.current.innerHTML = playerError
          }
          console.error(
            `The script ${event.target.src} didn't load correctly. Please post this bug in Microsoft Teams at https://teams.microsoft.com/l/channel/19%3ac2229c6d432042c4a93fb988a15ddb58%40thread.skype/General?groupId=3e774362-643e-483a-9f81-18a6f701337a`
          )
        })
      }
      return () => {
        const bitmovinPlayer = window.bitmovin[containerId]
        bitmovinPlayer?.destroy()
      }
    }, [
      videoId,
      accountId,
      playerErrorCallback,
      playCallback,
      pauseCallback,
      playerReadyCallback,
      endCallback,
      autoplay,
      playerError,
      muted,
      containerId,
    ])

    return (
      <div aria-label={title} {...rest}>
        <div style={{ width, aspectRatio: '16/9' }} id={containerId} ref={container} />
      </div>
    )
  }
)

BitmovinPlayer.displayName = 'BitmovinPlayer'

BitmovinPlayer.propTypes = {
  /** Data for Bitmovin video player */
  videoData: PropTypes.shape({
    videoId: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
  }).isRequired,

  /** Callback to execute when video is ready to be played */
  onPlayerReady: PropTypes.func,

  /** Callback to execute when video has an error */
  onPlayerError: PropTypes.func,

  /** Callback to execute when video starts playing */
  onPlay: PropTypes.func,

  /** Callback to execute when video is paused */
  onPause: PropTypes.func,

  /** Callback to execute when video ends */
  onEnd: PropTypes.func,

  /** Whether video plays immediately */
  autoplay: PropTypes.bool,

  /** Translation strings */
  strings: PropTypes.shape({
    playerError: PropTypes.string.isRequired,
  }),
}

const VideoThumbnail = ({ setLoad, title, thumbnail }) => {
  const { t } = useTranslation()
  const onClick = useCallback(
    (e) => {
      trackLink(e)
      setLoad(true)
    },
    [setLoad]
  )
  // not the best translation pattern, but this is for screen readers and provides a little better idea of what is going on.
  const label = title
    ? `${title} ${t('video.placeholder.aria-label', 'Click to load video.')}`
    : t('video.placeholder.aria-label', 'Click to load video.')
  return (
    <button
      style={{
        display: 'block',
        cursor: 'pointer',
        width: '100%',
        aspectRatio: '16/9',
        backgroundImage: ` url(${brightcovePlayButtonPng}) , url(${thumbnail || stageWithPlaybuttonJpg})`,
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundPosition: 'center, center',
        backgroundSize: 'cover',
        border: 'none',
      }}
      data-link-name="Video Placeholder"
      aria-label={label}
      title={label}
      type="button"
      onClick={onClick}
      onKeyUp={({ key }) => {
        if (key === 'Enter') {
          onClick({ 'data-link-name': 'Video Placeholder' })
        }
      }}
    />
  )
}

const BitmovinPlayerFacade = forwardRef(({ autoplay = false, onPlayerReady, contentLocale, ...props }, ref) => {
  const [load, setLoad] = useState(autoplay)
  const facade = <VideoThumbnail heading={props.title} {...props} setLoad={setLoad} />
  const setPlayer = useBitmovinPlayerAnalytics()

  useEffect(() => {
    const url = new URL(window.location.href)
    const lang = getISO639_2LanguageCode(contentLocale ?? i18n.language)
    url.searchParams.set('lang', lang)
    window.history.replaceState({}, '', url.toString())
  }, [contentLocale])

  return (
    <ResponsiveProvider>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Suspense fallback={facade}>
          {load ? (
            <BitmovinPlayer
              ref={ref}
              autoplay
              onPlayerReady={(player) => {
                if (props.seekStartTime) {
                  player.seek(props.seekStartTime)
                }

                setPlayer(player)
                ref?.current?.focus()
                onPlayerReady?.(player)
              }}
              {...props}
            />
          ) : (
            facade
          )}
        </Suspense>
      </div>
    </ResponsiveProvider>
  )
})

export default BitmovinPlayerFacade

BitmovinPlayerFacade.propTypes = {
  title: PropTypes.string,

  /** Data for Bitmovin video player */
  videoData: PropTypes.shape({
    videoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,

  /** Seeks the time to start for the video */
  seekStartTime: PropTypes.number,

  /** Callback to execute when video is ready to be played */
  onPlayerReady: PropTypes.func,

  /** Callback to execute when video has an error */
  onPlayerError: PropTypes.func,

  /** Callback to execute when video starts playing */
  onPlay: PropTypes.func,

  /** Callback to execute when video is paused */
  onPause: PropTypes.func,

  /** Callback to execute when video ends */
  onEnd: PropTypes.func,

  /** Whether video plays immediately */
  autoplay: PropTypes.bool,

  /** Translation strings */
  strings: PropTypes.shape({
    playerError: PropTypes.string.isRequired,
  }),
}
