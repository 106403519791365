import React from 'react'
import { Card, Separator, Image, Bleed, H4 } from '@fs/zion-ui'
import { css } from '@emotion/core'
import RainbowDivider from './RainbowDivider'
import { useIsMdWidth } from '../hooks'
import { circlesBlueberryJpg, circlesFireJpg, circlesSeafoamJpg } from '../images'

const overlayCss = css`
  opacity: 0.8;
  position: absolute;
`

const flavoredOverlays = {
  fire: circlesFireJpg,
  seafoam: circlesSeafoamJpg,
  blueberry: circlesBlueberryJpg,
}

export default function RainbowCard({ flavor, image, noScrim, heading, children }) {
  const isMdWidth = useIsMdWidth()
  return (
    <Card margins={!isMdWidth ? 'sparse' : undefined}>
      <Bleed all>
        {!noScrim && (
          <div css={overlayCss}>
            <Image src={flavoredOverlays[flavor]} style={{ aspectRatio: '2.5 / 1' }} alt="" />
          </div>
        )}
        <Image src={image} width="auto" alt="" loading="lazy" style={{ aspectRatio: '2.5 / 1' }} />
      </Bleed>
      <Separator size="nano" />
      <H4>{heading}</H4>
      <Separator size="xxs" />
      <RainbowDivider thick and full of flavor={flavor} />
      <Separator size="xxs" />
      {children}
    </Card>
  )
}
