import React, { Children, useEffect, useState } from 'react'
import { List, ListItem, DialogOverlay, DialogOverlayActions, useStatusOverlay, Ellipsis } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { ContentCheck } from '@fs/zion-icon'
import { useHistory } from '@fs/zion-router'
import { useUser } from '@fs/zion-user'
import { flavors, playlistsConfig } from '../config'
import { usePlaylistsContext } from '../providers'
import CreatePlaylistButton from './buttons/CreatePlaylistButton'

export default function PlaylistsOverlay({ sessionId, createPlaylistOverlay, onPlaylistSelected, ...overlay }) {
  const [t] = useTranslation()
  const history = useHistory()
  const showStatusOverlay = useStatusOverlay()
  const [sessionInPlaylists, setSessionInPlaylists] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const { addToPlaylist, playlists, getPlaylistsForItem, defaultPlaylist } = usePlaylistsContext()
  const { signedIn } = useUser()
  const onClick = (change) => {
    setSessionInPlaylists((current) => [...current, change])
    setSubmitting(true)
    addToPlaylist({ playlistId: change.id, sessionId })
      .then(() => {
        onPlaylistSelected?.(change)
        showStatusOverlay({
          message: t('playlists.saved.alert', 'Saved to playlist.'),
          type: 'success',
          Icon: ContentCheck,
          actionLabel: t('playlists.saved.nav-to-playlist', 'Go to Playlist'),
          actionCallback: () => history.push(`/playlists/${change.id}`),
        })
      })
      .then(overlay.close)
  }
  useEffect(() => {
    if (playlists?.length > 0) {
      getPlaylistsForItem({ sessionId, signedIn }).then((p) => setSessionInPlaylists(p))
    }
  }, [getPlaylistsForItem, playlists, sessionId, signedIn])
  const inDefaultPlaylist = Boolean(sessionInPlaylists?.some((p) => p.id === defaultPlaylist.id))
  return (
    <DialogOverlay
      {...overlay}
      size="sm"
      title={t('playlists.overlay.heading', 'Add to...')}
      footer={
        <DialogOverlayActions>
          {playlists.length < playlistsConfig.playlistLimit && (
            <CreatePlaylistButton playlistsOverlay={overlay} disabled={submitting} {...createPlaylistOverlay} />
          )}
        </DialogOverlayActions>
      }
    >
      {playlists && (
        <List>
          <ListItem
            data-testid="watch-later-playlist"
            dense
            disabled={inDefaultPlaylist || defaultPlaylist.size === playlistsConfig.sessionLimit}
            startElement={
              inDefaultPlaylist ? (
                <ListItem.Icon Icon={ContentCheck} color={flavors.mango.solid} size="xs" />
              ) : (
                <ListItem.Filler />
              )
            }
            primaryText={
              <Ellipsis>
                <strong>{t('playlists.playlist.default.name', 'Watch Later')}</strong>
              </Ellipsis>
            }
            secondaryText={t('playlists.playlist.size', '{ playlistSize } videos', {
              playlistSize: defaultPlaylist.size || 0,
            })}
            onClick={() => onClick(defaultPlaylist)}
            linkName="Add To Default Playlist"
          />
          {Children.toArray(
            playlists.map((playlist) => {
              const checked = Boolean(sessionInPlaylists?.some((p) => p.id === playlist.id))
              const full = playlist.size >= playlistsConfig.sessionLimit

              let secondaryText = t('playlists.playlist.size', '{ playlistSize } videos', {
                playlistSize: playlist.size || 0,
              })
              if (full) {
                secondaryText = t('playlists.playlists.size-status', '{ playlistSize } videos (FULL)', {
                  playlistSize: playlist.size,
                })
              }

              return (
                !playlist.default && (
                  <ListItem
                    dense
                    disabled={checked || full}
                    startElement={
                      checked ? (
                        <ListItem.Icon Icon={ContentCheck} color={flavors.mango.solid} size="xs" />
                      ) : (
                        <ListItem.Filler />
                      )
                    }
                    primaryText={
                      <Ellipsis>
                        <strong>{playlist.name}</strong>
                      </Ellipsis>
                    }
                    secondaryText={secondaryText}
                    key={playlist.id}
                    onClick={() => onClick(playlist)}
                    linkName="Add to Other Playlist"
                  />
                )
              )
            })
          )}
        </List>
      )}
    </DialogOverlay>
  )
}
